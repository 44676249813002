import React, { useState, useEffect, } from 'react'
import './Home.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircleIcon from '@mui/icons-material/Circle';
import StarRateIcon from '@mui/icons-material/StarRate';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useLocation } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Swiper from 'swiper';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import homeback from '../../Images/backhomeimg.webp';
import slideone from '../../Images/slider-01.webp';
import slidetwo from '../../Images/slider02.webp';
import shoesback from '../../Images/shoesbackimg.webp';
import cartone from '../../Images/cartoneimg.webp';
import carttwo from '../../Images/carttwoimg.webp';
import cartthree from '../../Images/cartthree.webp';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import firstimg from '../../Images/seclastimg.webp';
import secondimg from '../../Images/lastshoesimg.webp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import bgimg from '../../Images/bannersecimg.webp';
import { useCart } from '../CartContext/CartContext';


function Home() {
  const [isFavorite, setIsFavorite] = useState(false);
  const [wishlistItems, setWishlistItems] = useState({});
  const [isInCart, setIsInCart] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  // const [cartItems, setCartItems] = useState({});
  const { wishlistData, updateWishlist,cartItems,increaseCount,increaseCountforbag} = useCart();

  // const [productId, setProductId] = useState('');
  const bgimg = '../../Images/bannersecimg.webp';
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <ArrowCircleLeftOutlinedIcon />,
    nextArrow: <ArrowCircleRightOutlinedIcon />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  }


  const [data, setdata] = useState([])
  useEffect(() => {
    {
      axios.get('https://dev.ahura.xyz:3002/api/product/user/productList')
        .then((response) => {
          setdata(response.data.result.docs)
          console.log(response)
        })
    }
  }, [])

  // Add this function for animation
  function startAnimation() {
    const homeTxt = document.querySelector('.home-txt');
    if (homeTxt) {
      homeTxt.classList.add('active');
    } else {
      console.error("Element with class 'home-txt' not found.");
    }
  }
  // Trigger the animation when the page loads
  window.addEventListener('load', startAnimation);

  const img = [];
  const [current, setCurrent] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const $bannerImg = document.querySelector(".banner img");

    if (!$bannerImg) {

      return;
    }

    const change = setInterval(() => {
      if (!isPaused) {
        setCurrent((prev) => (prev === img.length - 1 ? 0 : prev + 1));
        $bannerImg.setAttribute("src", img[current]);
      }
    }, 2000);

    return () => {
      clearInterval(change);
    };
  }, [current, isPaused]);

  const next = () => {
    setCurrent((prev) => (prev === img.length - 1 ? 0 : prev + 1));
  };

  const before = () => {
    setCurrent((prev) => (prev === 0 ? img.length - 1 : prev - 1));
  };
  // slider
  const swiper = new Swiper(".swiper-slider", {
    //  parameters
    centeredSlides: true,
    slidesPerView: 1,
    infinite: true,
    freeMode: false,
    loop: true,
    mousewheel: false,
    keyboard: {
      enabled: true
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },

    // pagination
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: false,
      clickable: true
    },

    //  navigation
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },

    // Responsive breakpoints
    breakpoints: {
      640: {
        slidesPerView: 1.25,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 20
      }
    }
  });

  document.body.onmousemove = e => {
    document.querySelectorAll('.card-effect').forEach(card => {
      const rect = card.getBoundingClientRect();
      const relX = e.clientX - rect.left;
      const relY = e.clientY - rect.top;
      card.style.setProperty("--mouse-x", relX + "px");
      card.style.setProperty("--mouse-y", relY + "px");
    });
  };
  

  const addToCart = async (value, productId, productBrandId, productSize) => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    console.log('Adding to cart. Product ID:', productId);
    
    if (token) {
      const data = {
        brandId: value.productBrandData[0]._id,
        productId: value._id,
        productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
        quantity: 1      
      };
      
      try {
        const response = await axios.post('https://dev.ahura.xyz:3002/api/cart/user/addProductToCart', data, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        if (response) {
          toast.success('Product added to the cart successfully');
          setRelatedProductsBags(prevState => ({
            ...prevState,
            [value._id]: true,
          }));
          
          increaseCountforbag();
         
          setIsInCart(true);
          console.log('Cart Items:', cartItems);
        } else {
          console.log('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error adding the product to the cart:', error);
      }
    } else {

      console.log('else', value)
      // Retrieve guest cart from local storage
      const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
      guestCart.push({
        ...value,
        quantity: 1,
        productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
      });

      // Update guest cart in local storage
      localStorage.setItem('guestCart', JSON.stringify(guestCart));
      toast.success('Product added to the cart successfully');
      
    }
  };
  const isProductInCart = (productId) => {
    console.log('cartItems:', cartItems);
    
    if (Object.keys(cartItems).length > 0) {
      console.log('Checking cartItems');
      return productId in cartItems;
    } else {
      console.log('Checking guestCart');
      const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
      return guestCart.some(item => item._id === productId);
    }
  };
  

  
  const [addedToWishlisticon, setAddedToWishlisticon] = useState([]);
    const [relatedProductsBags, setRelatedProductsBags] = useState({});
    const [relatedProductsFavorites, setRelatedProductsFavorites] = useState({});
    
  const isProductInWishlist = (productId) => {
    if (wishlistData.length > 0) {
      return wishlistData.some(item => item.productData.some(product => product._id === productId));
    } else {
      const guestWishlist = JSON.parse(localStorage.getItem('guestWishlist')) || [];
      return guestWishlist.some(item => item._id === productId);
    }
  };
  const createProductData = (value) => {
    return {
      brandId: value.productBrandData[0]._id,
      productId: value._id,
      productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
      quantity: 1
    };
  };


  const addToWishlist = async (value) => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const data = createProductData(value);
  
    if (token) {
      // User is logged in, check if the product is in the wishlistData
      if (isProductInWishlist(value._id)) {
        toast.warning('Product is already in the wishlist');
        return;
      }
  
      try {
        const response = await axios.post(
          'https://dev.ahura.xyz:3002/api/wishlist/user/addProductToWishlist',
          data,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }
        );
        // console.log('Before adding to wishlist:', relatedProductsFavorites);

        if (response) {
          toast.success('Product added to the wishlist successfully');
          setRelatedProductsFavorites(prevState => ({
            ...prevState,
            [value._id]: true,
          }));
          setAddedToWishlisticon(prevState => [...prevState, value._id]);
          increaseCount();
          updateWishlist();
        } else {
          console.log('Unexpected response structure:', response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          toast.warning('somthing is wrong, try again later')
          // setRelatedProductsFavorites(prevState => ({
          //   ...prevState,
          //   [value._id]: true,
          // }));
          // setAddedToWishlisticon(prevState => [...prevState, value._id]);
          // console.error('Conflict while adding to wishlist:', error.response.message);
         
        } else {
          console.error('Error adding to wishlist:', error);
        }
      }
    } else {
      if (isProductInWishlistForGuest(value._id)) {
        toast.warning('Product is already in the wishlist');
        return;
      }
  
      // Fetch the guest wishlist from local storage
      let guestWishlist = JSON.parse(localStorage.getItem('guestWishlist') || '[]');
  
      guestWishlist.push({
        ...value,
        quantity: 1,
        productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
      });
        localStorage.setItem('guestWishlist', JSON.stringify(guestWishlist));
  
      toast.success('Product added to the wishlist successfully');
    }
  };
  
  // Helper function for checking wishlist for guest users
  const isProductInWishlistForGuest = (productId) => {
    const guestWishlist = JSON.parse(localStorage.getItem('guestWishlist')) || [];
    return guestWishlist.some(item => item._id === productId);
  };
  





  return (
    <>
      {/* secone */}
      <section className="sec-home">
        <div className="background-images">
          <img src={homeback} alt="Background Image" />
        </div>
        <div className="container">
          <div className="banner-carousel ">
            <div class="swiper-btn-wrap">
            </div>
            <Carousel autoPlay infiniteLoop showThumbs={false}>

              <div className="homeslide">

                <div className="content-column">
                  <div className="innercol">
                    <div className="title-box">
                      <h2 className='titl'>  Exclusive  New Shoes </h2>


                    </div>
                    <p className="discp">Up To 30% Off All Shoes & Products.</p>
                    <div className="box-btn">
                      <NavLink to={'productlist'}>  <button>SHOP NOW</button></NavLink>
                    </div>
                  </div>
                </div>
                <div className="image-column">
                  <div className="circular-image">
                    <div className="back-image">
                      <img src={shoesback} alt="Background Image" />
                    </div>
                    <div className="overlay-image">
                      <img src={slideone} alt="Overlay Image" />
                    </div>
                  </div>

                </div>

              </div>
              <div className="homeslide">
                <div className="content-column">
                  <div className="title-box">
                    <h2 className='titl'>  Exclusive New Shoes</h2>

                  </div>
                  <p className="discp">Up To 30% Off All Shoes & Products</p>
                  <div className="box-btn">
                    <NavLink to={'productlist'}>  <button>SHOP NOW</button></NavLink>
                  </div>

                </div>
                <div className="image-column">
                  <div className="circular-image">
                    <div className="back-image">
                      <img src={shoesback} alt="Background Image" />
                    </div>
                    <div className="overlay-image">
                      <img src={slideone} alt="Overlay Image" />
                    </div>
                  </div>

                </div>
              </div>

            </Carousel>

          </div>
          <h2 className='text-shape'>NEW2023</h2>
        </div>

      </section>

      {/* sectwo */}
      <section id="collection">
        <div class="container">
          <div class="collections">
            <div class="content2 card-effect">
              <div class="img-content2">
                <h3 className='carttitle'>Sports Shoes</h3>
                <h4>From $29.99</h4>
              </div>
              <img src={cartone} alt="img" />
            </div>
            <div class="content2 card-effect">
              <div class="img-content2">
                <h3 className='carttitle'>Latest Shoes</h3>
                <h4>From $29.99</h4>
              </div>
              <img src={carttwo} alt="img" />
            </div>
            <div class="content2 card-effect">
              <div class="img-content2">
                <h3 className='carttitle'>Office Shoes</h3>
                <h4>From $29.99</h4>
              </div>
              <img src={cartthree} alt="img" className='inimg' />
            </div>
          </div>
        </div>
      </section>

      {/* secthree */}
      <section id="sellers">
        <div className="container">
          <div class="seller">
            <h3>Featured Items</h3>
            <div className="tit-p">
              <p >There are many variations of passages of Lorem Ipsum available</p>
            </div>
            <div class="best-seller">
              <div className="row">
                {
                  data.slice(0, 8).map((value) => {
                    const isFavorite = !!wishlistItems[value.productId];
                    return (
                      <div class="best-p2">
                        <NavLink to={`/productdetail/${value._id}`}>

                          {value.productOnsale === 2 && (
                            <div class="sale-label">
                              <p>sale</p>
                            </div>
                          )}
                          <img src={`https://${value.featuredProductImage}`} alt="img" className='hoverimg' />

                          <div class="best-p1-txt">
                            <div className="name-of-p">
                              <p className="name-heart">
                                <span>{value.productBrandData[0].brandName}</span>

                              </p>
                            </div>
                            <div class="pricee">
                              {value.productName}
                            </div>
                            <div className="price">
                              <span className="black-text">$ {value.productPrice}</span>

                            </div>

                            <div className="buy-now">
                              <a href="#" >

                              </a>
                            </div>
                          </div>
                          <div className="producticons">
                            <span>
                              <i
                                className="wishlisticon"
                                onClick={() => addToWishlist(value)}
                                title="Add to Wishlist"
                              >
                                <NavLink to='/'>
                                  {relatedProductsFavorites[value._id] || isProductInWishlist(value._id) ? <FavoriteIcon /> : <FavoriteBorderIcon />}

                                </NavLink>
                              </i>
                            </span>
                            <span>
                              <i
                                className="wishlisticon"
                                title="Add to Cart"
                                onClick={() => addToCart(value, value.productBrandData[0]._id,)}
                              >
                                <NavLink to='/'>
                                  {relatedProductsBags[value._id] ||  isProductInCart(value._id) ? <ShoppingBagIcon /> : <ShoppingBagOutlinedIcon />}
                                </NavLink>
                              </i>
                            </span>
                          </div>

                        </NavLink>
                      </div>
                    );
                  })
                }

              </div>

            </div>
          </div>
        </div>

      </section>

      {/* secfour */}
      <section className="bg-color-f2 position-relative z-index-1">
        <div className="container pt--0 pb--0">
          <div className="row divider-wrap divider-style1">
            <div className="col-lg-6">
              <div className="divider-content" data-title="NEW">
                <h4 className="dividertitle">Saving 50%</h4>
                <h2 className="titledivid">All Online Store</h2>
                <p className="descdivide">Offer Available All Shoes & Products</p>
                <NavLink to="productlist">  <div className="btn-divider">Shop Now</div></NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-layer-wrap">
          <div className="bg-layer-style z-index--1 parallax" data-speed="1.05"
            style={{

              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 50%',
            }}
          >
          </div>
          <div id="jarallax-container-0" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', pointerEvents: 'none', zIndex: '-100' }}>
            <div style={{ backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', position: 'absolute', top: '0px', left: '0px', width: '750px', height: '375.3px', overflow: 'hidden', pointerEvents: 'none', marginTop: '205.35px', transform: 'translate3d(0px, -223.537px, 0px)' }}></div>
          </div>
        </div>
      </section>

      {/* secfive */}
      <section className='cartslider'>
        <div className='container'>
          <div className="slidecartt">
            <h3>Best Seller</h3>
            <div className="tit-p">
              <p >There are many variations of passages of Lorem Ipsum available</p>
            </div>

            <Slider {...settings}>
              {data.slice(0, 5).map((value) => (
                <div class="best-slide">
                  <NavLink to={`/productdetail/${value._id}`}>
                    <img src={`https://${value.featuredProductImage}`} alt="img" />

                    <div class="best-p1-txtt">
                      <div className="name-of-p">
                        <p className="name-heart">
                          <span>{value.productBrandData[0].brandName}</span> </p>
                      </div>
                      <div class="pricee">
                        {value.productName}
                      </div>
                      <div className="price">
                        <span className="black-text">$ {value.productPrice}</span>

                      </div>

                      <div className="buy-now">
                        <a href="#" >

                        </a>
                      </div>
                      <div className="producticons">
                        <span>
                          <i
                            className="wishlisticon"
                            onClick={() => addToWishlist(value)}
                            title="Add to Wishlist"
                          >
                            <NavLink to='/'>
                              {relatedProductsFavorites[value._id] || isProductInWishlist(value._id) ? <FavoriteIcon /> : <FavoriteBorderIcon />}

                            </NavLink>
                          </i>
                        </span>
                        <span>
                          <i
                            className="wishlisticon"
                            title="Add to Cart"
                            onClick={() => addToCart(value, value.productBrandData[0]._id,)}
                          >
                            <NavLink to='/'>
                              {relatedProductsBags[value._id] || isProductInCart(value._id)  ? <ShoppingBagIcon /> : <ShoppingBagOutlinedIcon />}
                            </NavLink>
                          </i>
                        </span>
                      </div>
                    </div>

                  </NavLink>
                </div>
              ))}
            </Slider>
          </div>

        </div>

      </section>
      {/* secsix */}

      <section className='latsec'>
        <div class="container pt--0 pb--0">
          <div class="row flex-md-row-reverse justify-content-between divider-wrap divider-style2">
            <div class="col-lg-6">
              <div class="divider-thumb-content">
                <div class="thumb">
                  <a>
                    <img src={firstimg} width="570" height="350" alt="Image-HasTech" />
                  </a>
                </div>
                <div class="contentt">
                  <h2 class='firsttitle'>Sports Shoes</h2>
                  <div className="tit-last">
                    <p >Up To 30% Off All Shoes &amp; Products</p>
                  </div>

                  <div className="boxtwo-btn">
                    <NavLink to="productlist"> <button>SHOP NOW</button></NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="divider-thumb-content">
                <div class="thumb ">
                  <a href="shop.html">
                    <img src={secondimg} width="570" height="700" alt="Image-HasTech" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />

    </>
  )
}

export default Home