import React from 'react'
import Header from '../Components/Header/Header'
import Sidebar from '../Components/ProfileSidebar/Sidebar'
import Footer from '../Components/Footer/Footer'

function ProfileLayout({Page}) {
  return (
    <>
     <div className="wrapper-main-cnt-userbodycnt">
          <div className="userbodycnt-Layout">
     <Header/>
     <Sidebar>
     {Page}
     </Sidebar>
  
   <Footer/>
   </div>
   </div>
    </>
  )
}

export default ProfileLayout