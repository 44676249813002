import React, { useState } from 'react';
import './Faqs.css';
import { NavLink } from 'react-router-dom';


function Faqs() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleQuestionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
    <section className='regularbanner'>
        <div className="bannnercontent">
          <h2 className='bannertitle'>FAQs</h2>
          <NavLink> <a href="/" className='bannfirstlink'>Home </a><a href="/" className='bannlink'>/ FAQs </a></NavLink>
        </div>
      </section>
      <section id="faqssec" className="faq section-bg">
        <div className="container" data-aos="fade-up">
         

          <div className="faq-list">
            <ul>
              {[1, 2, 3, 4, 5].map((index) => (
                <li key={index} data-aos="fade-up" data-aos-delay={index * 100}>
                  <i className="bx bx-help-circle icon-help"></i>{' '}
                  <a
                    onClick={() => handleQuestionClick(index)}
                    className={`collapsed ${activeIndex === index ? 'active' : ''}`}
                  >
                  {index} Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit, dolorum?
                  </a>
                  <div className={`collapse ${activeIndex === index ? 'show' : ''}`}>
                    <p>
                    {index} Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore, laborum quibusdam iure nostrum aliquam iusto sunt temporibus molestias provident odit? 
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faqs;
