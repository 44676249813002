import React from 'react'
import "./Sidebar.css";
import { RxDashboard } from 'react-icons/rx'
import { TbFileInvoice } from 'react-icons/tb'
import { FiUsers } from 'react-icons/fi'
import { AiOutlineTags } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import { MdOutlineLogout } from 'react-icons/md'
import {CgProfile} from 'react-icons/cg'
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';


const routes = [
 
  {
    path: "/profile",
    name: "Account Details",
    icons: <CgProfile />,
    id: "profile",
    key: "profile"
  },
 
  {
    path: "/order",
    name: "My Order",
    icons: <AiOutlineTags />,
    id: "order",
    key: "order"
  },
 
 
 
  
  
  
]


function Sidebar({ children }) {
  
  const navigate = useNavigate();
  
  const handleLogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('token')
    localStorage.removeItem('type')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('type')
    navigate("/")
  }

  return (
    <>
     <section className='regularbanner'>
    <div className="bannnercontent">
        <h2 className='bannertitle'>ACCOUNT</h2>
         <NavLink> <a href="/" className='bannfirstlink'>Home </a><a href="" className='bannlink'>/ ACCOUNT </a></NavLink>
      </div>
  </section>
   
  <div className="conatiner">
  <div className='UserSidebarr'>
      <div className="container flex-body">
      <div className="usersidebarr ">
          {routes.map((route) => (
            <NavLink className="userlink" to={route.path} key={route.name} id={route.id}  >
              <div className="ussericon">{route.icons}</div>
              <div className="user-link_text">{route.name}</div>
            </NavLink>
          ))}
          <NavLink to="/logout" key="logout" className="userlink" id="logout" onClick={(e) => handleLogOut(e)} >
            <div className="ussericon"><MdOutlineLogout/></div>
            <div className="user-link_text">Log Out</div>
          </NavLink>
        </div>
        <main className='main-wrap-user' > <div className='userdashboardcnt'>{children}</div> </main>
      </div>
    </div>
  </div>
    </>
  )
}

export default Sidebar