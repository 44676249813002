
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './OrderDetails.css'
import axios from 'axios';
import { useParams } from 'react-router-dom';

function OrderDetails() {
  const [orderDetails, setOrderDetails] = useState(null);
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`http://192.168.1.23:3000/api/order/user/userOrderDetails/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response && response.data && response.data.result && response.data.result.docs) {
          setOrderDetails(response.data.result.docs[0]); 
        } else {
          console.error('Error fetching order details:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    if (token) {
      fetchData();
    } else {
      console.error('User not authenticated');
    }
  }, [token, id]);
  return (
    <>
   <div class="order-details">
  <h1>Order Details</h1>
  {orderDetails ? (
    <div class="order-info">
      <div class="order-id">
        <h2>Order No:</h2>
        <p>{orderDetails.OrderId}</p>
      </div>
      <div class="order-total">
        <h2>Order Date:</h2>
        <p>{orderDetails.OrderDate}</p>
      </div>

      <div class="other-details">
        <h2>Other Details:</h2>
      </div>

      <table style={{margin:"0"}}>
        <thead>
          <tr>
            <th>Product</th>
            <th>Product Details</th>
          </tr>
        </thead>
        <tbody>
          {orderDetails.products.map((product) => (
            <tr key={product._id} >
              <td>
                <img
                  src={`https://${product.productImage}`}
                  alt={product.productName}
                  style={{ width: '100px', height: '100px' }}
                />
              </td>
              <td>
                <div>
                  <p>{product.productName}</p>
                  <p>{product.productSize}</p>
                  <p>${product.productPrice}</p>
                  <p>
                    QTY : <span className='ordertext'>{product.productQuantity}</span>
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p>Loading order details...</p>
  )}
</div>

    </>
  )
}

export default OrderDetails
