import React from 'react'
import { NavLink, useParams } from 'react-router-dom';
import './ContactUs.css'
import contactimg from '../../Images/home.webp';
import phoneimg from '../../Images/phone.webp';
import emailimg from '../../Images/emailimg.webp';
import topdesign from '../../Images/topdesign.webp';
import sectopdesign from '../../Images/sectopdesign.webp';
import designthree from '../../Images/designthree.webp';
import lastdesign from '../../Images/lastdesign.webp';
function ContactUs() {
  return (
    <>
    <section className='regularbanner'>
    <div className="bannnercontent">
        <h2 className='bannertitle'>CONTACT US</h2>
         <NavLink> <a href="/" className='bannfirstlink'>Home </a><a href="/" className='bannlink'>/ CONTACT US </a></NavLink>
      </div>
  </section>
    <section class="contact-area contact-page-area">
      <div class="container">
        <div class="row contact-page-wrapper">
          <div class="col-xl-9">
            <div class="contact-form-wrap aos-init aos-animate" data-aos="fade-right">
              <div class="contact-form-title">
                <h2 class="title">We Are Here! <br/>Please Send A Quest</h2>
              </div>
              
              <div class="contact-form">
                <form id="contact-form" action="https://whizthemes.com/mail-php/raju/arden/mail.php" method="POST">
                  <div class="row row-gutter-20">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input class="form-control" type="text" name="con_name" placeholder="Name *"/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input class="form-control" type="email" name="con_email" placeholder="Email *"/>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <input class="form-control" type="text" placeholder="Subject (Optinal)"/>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group mb--0">
                        <textarea class="form-control" name="con_message" placeholder="Message"></textarea>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group mb--0">
                        <button class="btn-contact" type="submit">Send Message</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
             
              <div class="form-message"></div>
              <div class="shape-group-style2">
                <div class="shape-group-one"><img src={topdesign} width="193" height="168" alt="Image-HasTech"/></div>
                <div class="shape-group-two"><img src={sectopdesign} width="221" height="113" alt="Image-HasTech"/></div>
                <div class="shape-group-three"><img src={designthree} width="129" height="147" alt="Image-HasTech"/></div>
                <div class="shape-group-four"><img src={lastdesign} width="493" height="340" alt="Image-HasTech"/></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="contact-info-wrap">
              <div class="contact-info">
                <div class="row">
                  <div class="col-lg-4 col-xl-12">
                    <div class="info-item aos-init aos-animate" data-aos="fade-left">
                      <div class="icon">
                        <img src={contactimg} width="69" height="65" alt="Image-HasTech"/>
                      </div>
                      <div class="info">
                        <h5 class="title">Address</h5>
                        <p>Your address goes here. 123 Your Location</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xl-12">
                    <div class="info-item aos-init aos-animate" data-aos="fade-left" data-aos-delay="60">
                      <div class="icon">
                        <img src={phoneimg} width="65" height="65" alt="Image-HasTech"/>
                      </div>
                      <div class="info">
                        <h5 class="title">Phone No</h5>
                        <p>
                          <a href="tel://+00123456789">+00123456789</a><br/>
                          <a href="tel://+00123456789">+00123456789</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xl-12">
                    <div class="info-item aos-init aos-animate" data-aos="fade-left" data-aos-delay="120">
                      <div class="icon">
                        <img src={emailimg} width="65" height="65" alt="Image-HasTech"/>
                      </div>
                      <div class="info">
                        <h5 class="title">Email / Web</h5>
                        <p>
                          <a href="mailto://demo@example.com">demo@example.com</a><br/>
                          <a href="mailto://www.example.com">www.example.com</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default ContactUs