import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Order.css';
import { NavLink } from 'react-router-dom';


function Order() {
  const [orderList, setOrderList] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  useEffect(() => {
    const getOrderList = async () => {
      try {
        let headers = {};
        if (token) {
          headers = {
            Authorization: `Bearer ${token}`,
          };
        } else {
          console.error('User not authenticated');
          setLoading(false);
          return;
        }
        const response = await axios.get('https://dev.ahura.xyz:3002/api/order/user/userOrders', {
          headers,
          params: {
            orderstatus: 1,
          },
        });

        console.log('Full response:', response);

        if (response && response.data && response.data.result && response.data.result.docs) {
          setOrderList(response.data.result.docs);
          console.log('Order List:', response.data.result.docs);
        } else {
          console.error('API Error:', response);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching order list:', error);
        setLoading(false);
      }
    };

    getOrderList();
  }, [token]);

  if (loading) {
    return <p>Loading order list...</p>;
  }

  return (
    <div className="order-list-container">
      <table>
        <thead>
          <tr>
            <th>product</th>
            <th>product Details</th>
          </tr>
        </thead>
        <tbody>
          {orderList ? (
            orderList.map((order) => (
              <tr key={order._id}>
                <td>
                <NavLink to={`/orderdetails/${order._id}`}>
                  <img src={`https://${order.products[0].productImage}`} alt={order.products[0].productName}
                   style={{ width: '100px', height: '100px' }} />
                </NavLink>
                </td>
                <td>
                  <div>
                    <p>Order No: <span className='ordertext'>{order.OrderId}</span></p>
                    <p>Total Amount: <span className='ordertext totalprise'> ${order.subTotal}</span> </p>
                    <p>No. of Products: <span className='ordertext'> {order.totalQuantity}</span></p>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No orders found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Order;
