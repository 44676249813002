import React, { useState, useEffect } from 'react';
import './Checkout.css'; // Import your CSS file
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';

function Checkout() {


  const location = useLocation();
  const [subtotal, setSubtotal] = useState(0);
  const navigate = useNavigate();
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  const searchParams = new URLSearchParams(location.search);
  const initialCartItems = [];
  searchParams.forEach((value, key) => {
    initialCartItems.push({
      productId: parseInt(key),
      quantity: parseInt(value),
    });
  });
  const selectedSize = searchParams.get('size');

  useEffect(() => {

    console.log('Selected Size:', selectedSize);

  }, [selectedSize, navigate]);

  const [localcartdata, setlocalcartdata] = useState();
  const [cartItems, setCartItems] = useState(initialCartItems);
  const decreaseQuantity = (productId) => {
    const updatedCart = cartItems.map((item) => {
      if (item._id === productId) {
        if (item.quantity > 1) {
          item.quantity -= 1;
        }
      }
      return item;
    });
    setCartItems(updatedCart);
    calculateSubtotal(updatedCart);
  };

  const increaseQuantity = (productId) => {
    const updatedCart = cartItems.map((item) => {
      if (item._id === productId) {
        item.quantity += 1;
      }
      return item;
    });
    setCartItems(updatedCart);
    calculateSubtotal(updatedCart);
  };

  const updateQuantity = (productId, newQuantity) => {
    const updatedCart = cartItems.map((item) => {
      if (item._id === productId) {
        item.quantity += newQuantity;
        item.price = item.productData[0].productPrice * item.quantity;
      }
      return item;
    });

    setCartItems(updatedCart);
  };

  const calculateSubtotal = (items) => {
    const total = items.reduce((acc, item) => {
      if (item.productData && item.productData.length > 0 && item.productData[0].productPrice !== undefined) {
        acc += item.productData[0].productPrice * item.quantity;
      } else if (item.productPrice !== undefined) {
        acc += item.productPrice * item.quantity;
      }
      return acc;
    }, 0);

    console.log('Total:', total);
    console.log('Items:', items);

    setSubtotal(total);
  };





  useEffect(() => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');

    if (token) {
      axios
        .get('https://dev.ahura.xyz:3002/api/cart/user/getcheckout', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setCartItems(response.data.result.docs);

          calculateSubtotal(response.data.result.docs);

          localStorage.setItem('cartItems', JSON.stringify(response.data.result.docs));
          console.log('cartItems', response.data.result.docs)
        })
        .catch((error) => {
          console.error('Error fetching cart:', error);
        });
    } else {
      const localCartItems = JSON.parse(localStorage.getItem('guestCart')) || [];
      setCartItems(localCartItems);
      console.log(localCartItems)
      calculateSubtotal(localCartItems);

    }
  }, [navigate, token]);

  const [contactInfo, setContactInfo] = useState({
    email: '',
    fullName: '',
    phoneNumber: '',
    password: '',
    username: '',
  });

  const [orderSuccessDialogOpen, setOrderSuccessDialogOpen] = useState(false);
  const [userExistsDialogOpen, setUserExistsDialogOpen] = useState(false);
  const tokenFromLocalStorage = localStorage.getItem('token') || sessionStorage.getItem('token');

  let finalToken = tokenFromLocalStorage;
  if (!tokenFromLocalStorage) {
    
    finalToken = token;
  }

  const handlePlaceOrder = async () => {
    let response; 
    
    try {

      let orderData = {};
      let orderEndpoint = '';

      if (token) {
        orderData = {
          products: cartItems.map((item) => ({
            productId: item.productData[0]._id,
            productName: item.productData[0].productName,
            productItemNo: item.productData[0].productItemNo,
            productBrand: item.brandData[0].brandName,
            productPrice: item.productData[0].productPrice,
            productOnsale: item.productData[0].productOnsale,
            productDiscount: item.productData[0].discountValue,
            productDiscountedPrice: item.productData[0].productDiscountedPrice,
            productImage: item.productData[0].featuredProductImage,
            productType: item.productData[0].productType,
            productQuantity: item.quantity,
            productSize: item.productSize,
          })),
          subTotal: subtotal,
          totalQuantity: cartItems.reduce((total, item) => total + item.quantity, 0),
          email: contactInfo.email,
          firstName: contactInfo.fullName,
          userMobileNo: contactInfo.phoneNumber,
          password: contactInfo.password,
          userName: contactInfo.username,
        };
        orderEndpoint = 'https://dev.ahura.xyz:3002/api/order/user/addOrder';
      } else {
        const localCartItems = JSON.parse(localStorage.getItem('pendingCartItems')) || [];

        orderData = {
          products: localCartItems.map((item) => ({
            productId: item._id,
            productImage: item.featuredProductImage,
            productName: item.productName,
            productPrice: item.productPrice,
            productSize: item.productSize,
            productType: item.productType,
            productQuantity: item.quantity,
            productOnsale: item.productOnsale,

          })),
          subTotal: subtotal,
          totalQuantity: localCartItems.reduce((total, item) => total + item.quantity, 0),
          email: contactInfo.email,
          firstName: contactInfo.fullName,
          userMobileNo: contactInfo.phoneNumber,
          password: contactInfo.password,
          userName: contactInfo.username,
        };

        orderEndpoint = 'https://dev.ahura.xyz:3002/api/order/user/addOrderGuest';

      }

      const response = await axios.post(orderEndpoint, orderData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },

      });
       if (response && response.status === 200) {
        localStorage.setItem('token', response.data.token);
        setOrderSuccessDialogOpen(true);
      } else {
        if (!tokenFromLocalStorage && response?.data?.token) {
          localStorage.setItem('token', response.data.token);
        }
        setOrderSuccessDialogOpen(true);
      }

    } catch (error) {
      if (error.response.status === 409) {
        setUserExistsDialogOpen(true);

      }
      else {

        setOrderSuccessDialogOpen(false);
        if (token === null && response?.data?.token) {
          localStorage.setItem('token', response.data.token);
        }
      }
      console.error('Error placing order:', error);
    }
  };

  const [totalQuantity, setTotalQuantity] = useState(0);
  const calculateTotalQuantity = (items) => {
    const totalQuantity = items.reduce((acc, item) => acc + item.quantity, 0);
    setTotalQuantity(totalQuantity);
  };
  useEffect(() => {
    calculateSubtotal(cartItems);
    calculateTotalQuantity(cartItems);
  }, [cartItems]);
  const handleChange = (e) => {
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };



  return (

    <>
      <section className='regularbanner'>
        <div className="bannnercontent">
          <h2 className='bannertitle'>CHECKOUT PAGE</h2>
          <NavLink> <a href="/" className='bannfirstlink'>Home </a><a href="" className='bannlink'>/ CHECKOUT PAGE </a></NavLink>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="checkout-container">
            <div className='checkout-form'>
              <h4>Billing details</h4>
              <form >
                <div className="row">
                  <div className="mb-1">
                    <label>Email address *</label>
                    <input
                      type="email"
                      value={contactInfo.email}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="enter your email"
                      id="email"
                      name="email"
                      required
                      style={{
                        width: '100%',
                        padding: '8px',
                        height: '60px',
                        border: '1px solid #e8e8e8',
                        fontSize: '16px',
                      }}

                    />
                  </div>
                </div>
                <div className="row">
                  <div className="mb-1">
                    <label>Phone number *</label>
                    <input
                      type="tel"
                      value={contactInfo.phoneNumber}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="enter your phone number"
                      id="phoneNumber"
                      name="phoneNumber"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="mb-1">
                    <label>Full Name *</label>
                    <input
                      type="text"
                      value={contactInfo.fullName}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="enter your full name"
                      id="fullName"
                      name="fullName"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3">
                    <label>Username *</label>
                    <input
                      type="text"
                      value={contactInfo.username}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="username"
                      id="username"
                      name="username"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3">
                    <label>Password *</label>
                    <input
                      type="password"
                      value={contactInfo.password}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="enter your password"
                      id="password"
                      name="password"
                      required
                    />
                  </div>
                </div>
              </form>

            </div>
            <div className="cart-details">
              <h5></h5>
              {cartItems.map((value) => (
                <tr className="itemcheckout" key={value._id}>

                  <div class='imagee'>
                    <NavLink to={`/productDetail/${value.productData && value.productData.length > 0 ? value.productData[0]._id : value._id}`}>
                      <img
                        src={`https://${value.productData && value.productData.length > 0 ? value.productData[0].featuredProductImage : value.featuredProductImage}`}
                        alt={value.productData && value.productData.length > 0 ? value.productData[0].productName : value.productName}
                        width={90}
                        height={90}
                      />
                    </NavLink>
                  </div>
                  <div className="descriptioncheck">

                    <NavLink to={`/productdetail/${value.productData && value.productData.length > 0 ? value.productData[0]._id : value._id}`}>
                      {value.productData && value.productData.length > 0 ? value.productData[0].productName : value.productName}
                    </NavLink>

                  </div>
                  <div className="descriptioncheck">
                    <NavLink to={`/productdetail/${value.productData && value.productData.length > 0 ? value.productData[0]._id : value._id}`}>
                      {value.productSize}
                    </NavLink>
                  </div>
                  <div className="totalprice">
                    ${value.productData && value.productData.length > 0 ? value.productData[0].productPrice : value.productPrice}
                  </div>
                </tr>
              ))}

              <div className="checkout-sectionn">
                <div className="total-pricecheckout">Total :  $ {subtotal.toFixed(2)}</div>
                <div className="total-pricecheckout">Total Quentity :   {totalQuantity}</div>
                <button className="checkout-btn  " onClick={handlePlaceOrder}>
                  PLACE ORDER
                </button>
                <Dialog
                  open={orderSuccessDialogOpen}
                  onClose={() => setOrderSuccessDialogOpen(false)}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle>Order Success</DialogTitle>
                  <DialogContent>
                    <p>Your order has been placed successfully!</p>
                    <Button onClick={() => setOrderSuccessDialogOpen(false)}>OK</Button>
                  </DialogContent>
                </Dialog>
                <Dialog
                  open={userExistsDialogOpen}
                  onClose={() => setUserExistsDialogOpen(false)}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle>User Already Exists</DialogTitle>
                  <DialogContent>
                    <p>The user already exists. Please log in or use a different email/username.</p>
                    <Button onClick={() => setUserExistsDialogOpen(false)}>OK</Button>
                  </DialogContent>
                </Dialog>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );

}

export default Checkout


