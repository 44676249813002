import React from 'react'
import "./Footer.css"
import atLogo from "../../Images/AT-White.png"
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PlaceIcon from '@mui/icons-material/Place';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { NavLink } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
// import LogoImgWhite from '../../Images/Quotastic-white.png'
import logofooter from '../../Images/logo-light.webp';
import paymentcard from '../../Images/payment-card.webp'

function Footer() {
  return (
    <>
      <section className='foot'>
        <div className="container">
          <footer>

            <div class="footer-container container">
              <div class="content_1">
                <img
                  src={logofooter}
                  alt="Your Alt Text Here"
                  style={{ width: '131px', height: '34px' }}
                />

                <p>The customer is at the heart of our<br />unique business model, which includes<br />design.</p>

                <div class="social-icons">
                  <a href="#" target="_blank" rel="noopener"><i ><FacebookIcon /></i></a>
                  <a href="#" target="_blank" rel="noopener"><i ><TwitterIcon /></i></a>
                  <a href="#" target="_blank" rel="noopener"><i ><InstagramIcon /></i></a>
                  <a href="#" target="_blank" rel="noopener"><i ><WhatsAppIcon /></i></a>
                </div>
              </div>
              <div class="content_2">
                <h4>Product</h4>
                <a href="/productlist">Men's Shoes</a>
                <a href="/productlist">Womens's Shoes</a>
                <a href="/productlist">Kid's Shoes </a>
                <a href="/productlist">Sale</a>
              </div>
              <div class="content_3">
                <h4>Customer Policies</h4>
                <a href="./privacypolicy">Privacy Policy</a>
                <a href="/faqs">FAQ</a>
                <a href="/termsandcondition" > T & C</a>
                <a href="https://codepen.io/sandeshbodake/full/Jexxrv" target="_blank"></a>
              </div>
              <div class="content_3">
                <h4>Keep In Touch</h4>
                <a href="./contact">Contact Us</a>
                <a href="./about" target="_blank">About Us</a>

              </div>

              {/* <div class="content_4">

              <a href="https://play.google.com/store/apps/details?id=et.nix.nixstore" target="_blank" class="market-btn apple-btn" role="button">
                <span class="market-button-subtitle">Download on the</span>
                <span class="market-button-title">App Store</span>
              </a>


              <a href="https://play.google.com/store/apps/details?id=et.nix.nixstore" target="_blank" class="market-btn google-btn" role="button">
                <span class="market-button-subtitle">Download on the</span>
                <span class="market-button-title">Google Play</span>
              </a>



            </div> */}
            </div>
            {/* <div class="f-design">
              <div class="f-design-txt container">
                <p>Design and Developed by Ahura Technosoft  <img
                  src={atLogo}
                  alt="Your Alt Text Here"
                  style={{
                    width: '20px',
                    height: '20px',
                    objectFit: 'contain'
                  }}
                /></p>
              </div>
            </div> */}

          </footer>

        </div>
      </section>
      <div class="footer-bottom">
        <div class="container pt--0 pb--0">
          <div class="row">
            <div class="col-md-7 col-lg-6">
              <p class="copyright">© 2023 Shome. Made with  <i class="fa fa-heart"></i> by <a target="_blank" href="https://themeforest.net/user/codecarnival/portfolio"> Ahura Technosoft <img src={atLogo} style={{
                width: '20px',
                height: '20px',
                objectFit: 'contain'
              }} alt="" /></a></p>
            </div>
            <div class="col-md-5 col-lg-6">
              <div class="paymentfooter">
                <a href="account-login.html"><img src={paymentcard} width="192" height="21" alt="Payment Logo" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>


  )
}

export default Footer