import React from 'react'
import './ProductDetail.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useState, useEffect } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { BrowserRouter as Router, Route, Switch, NavLink, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import StarIcon from '@mui/icons-material/Star';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShuffleOutlinedIcon from '@mui/icons-material/ShuffleOutlined';
import Slider from 'react-slick';
import convert from 'color-convert';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { useCart } from '../CartContext/CartContext';
const ProductDetail = () => {

  const [isSizeSelected, setIsSizeSelected] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [selectedSize, setSelectedSize] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [data, setdata] = useState([]);
  const [cartItems, setCartItems] = useState({});
  const [wishlistItems, setWishlistItems] = useState([]);

  const [selectedColor, setSelectedColor] = useState('');

  const [selectedQuantity, setSelectedQuantity] = useState(1); // State to store selected quantity

  const handleDecrement = () => {
    setSelectedQuantity(Math.max(selectedQuantity - 1, 1));
  };

  const handleIncrement = () => {
    setSelectedQuantity(selectedQuantity + 1);
  };

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value);
    setSelectedQuantity(isNaN(value) ? 1 : Math.max(value, 1));
  };

  const isLoggedIn = () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    return !!token;
  };
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Adjust the number of visible slides as needed
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Adjust the number of visible slides for smaller screens
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Adjust the number of visible slides for mobile screens
        }
      }
    ]
  }
  useEffect(() => {
    {
      axios.get('https://dev.ahura.xyz:3002/api/product/user/productList')
        .then((response) => {
          setdata(response.data.result.docs)
          console.log(response)
        })
    }
  }, [])
  const handleAddToCart = () => {
    // Make an API call to add the product to the cart using the user's token.
    const item = { ...selectedProduct, quantity };
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');

    if (token) {

      if (!selectedSize) {
        setIsSizeSelected(false);
        setErrorMessage('Please select a size before adding to the cart.');
        return; // Exit the function if size is not selected
      }
      const sendData = {
        brandId: productData[0].productBrandData[0]._id,
        productId: productData[0]._id, // Replace this with the actual brandId from your selectedProduct object
        productSize: productData[0].productBrandData[0]._id,
        quantity: 1,
        productSize: selectedSize,
      };

      axios
        .post(
          'https://dev.ahura.xyz:3002/api/cart/user/addProductToCart',
          sendData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }

        )
        .then((response) => {
          console.log('Product added to cart:', response.data);
          toast.success('Product added to cart!');
          // Redirect to the cart page after adding the product.
        })
        .catch((error) => {
          console.error('Error adding product to cart:', error);
        });
      setIsInCart(!isInCart);
    }
    if (selectedSize) {
      // Size is selected, add the product to the cart logic
      // Implement the logic to add the product to the cart here
    } else {

      setIsSizeSelected(false);

    }
    if (!selectedSize) {
      setIsSizeSelected(false);
      setErrorMessage('Please select a size before adding to the cart.');
      return; // Exit the function if size is not selected
    }
    const productInfo = {
      selectedSize,
      selectedColor,
      // ... other product details
    };
    const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
    guestCart.push({
      ...selectedProduct,
      quantity: 1,
      productSize: selectedSize || 'DefaultSize',
    });

    // Update guest cart in local storage
    localStorage.setItem('guestCart', JSON.stringify(guestCart));
    toast.success('Product added to the cart successfully');
    console.log('Product added to guest cart');
    addToCartFunction(selectedQuantity);

  };
  const addToCartFunction = (quantity) => {

    console.log(`Adding ${quantity} items to the cart`);
  };
  const handleThumbnailHover = (imageId) => {
    setSelectedImage(imageId);
  };
  // slider
  const handleThumbnailClick = (imageId) => {
    setSelectedImage(imageId);
  };
  const [isContentVisible, setIsContentVisible] = useState(false);
  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };


  const handleAddToWishlist = async () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      const productId = productData[0]._id;

      if (isFavorite) {
        toast.info('Product is already in the wishlist', {});
        return;
      }
      const datas = {
        brandId: productData[0].productBrandData[0]._id,
        productId: productId,
      };
      try {
        const response = await axios.post('https://dev.ahura.xyz:3002/api/wishlist/user/addProductToWishlist', datas, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        localStorage.setItem('data', JSON.stringify([...data, { productId }]));
        setIsFavorite(true);
        if (response) {
          toast.success('Product added to the wishlist successfully', {});
        } else {
          console.log('Unexpected response structure:', response.data);
          setErrorMessage('Something went wrong. Please try again later.');
        }
      } catch (error) {
        console.error('Error adding the product to the wishlist:', error);
        if (error.response) {
          console.log('Server response data:', error.response.data);
        }
      }

    } else {
      let guestWishlist = JSON.parse(localStorage.getItem('guestWishlist') || '[]');
      const productId = productData[0]._id;

      if (!guestWishlist.some(item => item.productId === productId)) {
        // Product is not in the guest wishlist, add it
        guestWishlist.push({
          productId: productId,
          brandId: productData[0].productBrandData[0]._id,
          productName: selectedProduct.productName,
          productBrandData: selectedProduct.productBrandData,
          productSize: selectedProduct.productSize[0],
          productPrice: selectedProduct.productPrice,
          featuredProductImage: selectedProduct.featuredProductImage,
          quantity: 1,
        });

        localStorage.setItem('guestWishlist', JSON.stringify(guestWishlist));
        setIsFavorite(true);
        toast.success('Product added to the wishlist successfully');
      } else {
        // Product is already in the guest wishlist
        toast.info('Product is already in the wishlist', {});
      }
    }
  };
  const [relatedProductsBags, setRelatedProductsBags] = useState({});

  const addToCart = async (value, productId, productBrandId, productSize) => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    console.log('Adding to cart. Product ID:', productId);
    
    if (token) {
      const data = {
        brandId: value.productBrandData[0]._id,
        productId: value._id,
        productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
        quantity: 1      
      };
      
      try {
        const response = await axios.post('https://dev.ahura.xyz:3002/api/cart/user/addProductToCart', data, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        if (response) {
          toast.success('Product added to the cart successfully');
          setRelatedProductsBags(prevState => ({
            ...prevState,
            [value._id]: true,
          }));
          
          increaseCountforbag();
         
          setIsInCart(true);
          console.log('Cart Items:', cartItems);
        } else {
          console.log('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error adding the product to the cart:', error);
      }
    } else {

      console.log('else', value)
      // Retrieve guest cart from local storage
      const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
      guestCart.push({
        ...value,
        quantity: 1,
        productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
      });

      // Update guest cart in local storage
      localStorage.setItem('guestCart', JSON.stringify(guestCart));
      toast.success('Product added to the cart successfully');
      
    }
  };
  const isProductInCart = (productId) => {
    if (Object.keys(cartItems).length > 0) {
      return productId in cartItems;
    } else {
      const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
      return guestCart.some(item => item._id === productId);
    }

  };
  const { wishlistData, updateWishlist,increaseCount,increaseCountforbag} = useCart();
  const [relatedProductsFavorites, setRelatedProductsFavorites] = useState({});
  const [addedToWishlisticon, setAddedToWishlisticon] = useState([]);


  const isProductInWishlist = (productId) => {
    if (wishlistData.length > 0) {
      return wishlistData.some(item => item.productData.some(product => product._id === productId));
    } else {
      const guestWishlist = JSON.parse(localStorage.getItem('guestWishlist')) || [];
      return guestWishlist.some(item => item._id === productId);
    }
  };
  const createProductData = (value) => {
    return {
      brandId: value.productBrandData[0]._id,
      productId: value._id,
      productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
      quantity: 1
    };
  };

  const addToWishlist = async (value) => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const data = createProductData(value);
  
    if (token) {
      // User is logged in, check if the product is in the wishlistData
      if (isProductInWishlist(value._id)) {
        toast.warning('Product is already in the wishlist');
        return;
      }
  
      try {
        const response = await axios.post(
          'https://dev.ahura.xyz:3002/api/wishlist/user/addProductToWishlist',
          data,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }
        );
        console.log('Before adding to wishlist:', relatedProductsFavorites);

        if (response) {
          toast.success('Product added to the wishlist successfully');
          setRelatedProductsFavorites(prevState => ({
            ...prevState,
            [value._id]: true,
          }));
          setAddedToWishlisticon(prevState => [...prevState, value._id]);
          increaseCount();
          updateWishlist();
        } else {
          console.log('Unexpected response structure:', response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          toast.warning('somthing is wrong, try again later')
          // setRelatedProductsFavorites(prevState => ({
          //   ...prevState,
          //   [value._id]: true,
          // }));
          // setAddedToWishlisticon(prevState => [...prevState, value._id]);
          // console.error('Conflict while adding to wishlist:', error.response.message);
         
        } else {
          console.error('Error adding to wishlist:', error);
        }
      }
    } else {
      if (isProductInWishlistForGuest(value._id)) {
        toast.warning('Product is already in the wishlist');
        return;
      }
  
      // Fetch the guest wishlist from local storage
      let guestWishlist = JSON.parse(localStorage.getItem('guestWishlist') || '[]');
  
      guestWishlist.push({
        ...value,
        quantity: 1,
        productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
      });
        localStorage.setItem('guestWishlist', JSON.stringify(guestWishlist));
  
      toast.success('Product added to the wishlist successfully');
    }
  };
  
  // Helper function for checking wishlist for guest users
  const isProductInWishlistForGuest = (productId) => {
    const guestWishlist = JSON.parse(localStorage.getItem('guestWishlist')) || [];
    return guestWishlist.some(item => item._id === productId);
  };




  // api
  const [productData, setProductData] = useState(null);
  console.log(productData)
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`https://dev.ahura.xyz:3002/api/product/user/${id}`)
      .then((response) => {
        setProductData(response.data.result.docs);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching product details:', error);
        setIsLoading(false);
      });
  }, [id]);


  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (!productData) {
    return <p>Product not found.</p>;
  }
  const selectedProduct = productData[0];
  const availableSizes = selectedProduct.productSize;
  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
    setIsSizeSelected(true); // Reset the message when a size is selected
  }

  const increment = () => {
    setQuantity(quantity + 1); // Increase quantity by 1
  };

  const decrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1); // Decrease quantity by 1, but not below 1
    }
  };
  const handleSizeClick = (size) => {
    setSelectedSize(size);
    setIsSizeSelected(true);
  };
  const getcolorHexCode = (colorName) => {
    const lowercaseColorName = colorName.toLowerCase();
    // Convert color name to hex
    const rgb = convert.keyword.rgb(lowercaseColorName);
    if (rgb) {
      const hex = convert.rgb.hex(rgb);
      return `#${hex}`;
    } else {
      console.warn(`Color not found for "${lowercaseColorName}"`);
      return 'black';
    }
  };

  return (
    <>
      <section className='regularbanner'>
        <div className="bannnercontent">
          <h2 className='bannertitle'>PRODUCT DETAILS</h2>
          <NavLink> <a className='bannfirstlink'>Home </a><a className='bannlink'>/ PRODUCT DETAILS </a></NavLink>
        </div>
      </section>
      <section className='productdetailone'>
        <div className="container">
          <div className="card-wrapper">
            <div className="cards">
              <div className="product-imgs">
                <div className="imgpro">
                  <img src={`https://${selectedImage || selectedProduct.featuredProductImage}`} alt="images" />
                </div>
                <div className="img-select">
                  <div className="img-item">
                    <img
                      src={`https://${productData[0].productImage[0]}`}
                      alt="images"
                      onMouseEnter={() => handleThumbnailHover(productData[0].productImage[0])}
                    />
                    <img
                      src={`https://${productData[0].productImage[1]}`}
                      alt="images"
                      onMouseEnter={() => handleThumbnailHover(productData[0].productImage[1])}
                    />
                    <img
                      src={`https://${productData[0].productImage[2]}`}
                      alt="images"
                      onMouseEnter={() => handleThumbnailHover(productData[0].productImage[2])}
                    />
                  </div>
                </div>
              </div>
              <div className="product-content">


                <h4 className="prodmaintitle">{selectedProduct.productName}</h4>
                <div className="prices">
                  {selectedProduct.productDiscountedPrice ? (
                    <>
                      <span className="blackcolor">$ {selectedProduct.productDiscountedPrice}</span>
                      <span className="oldprice"> ${selectedProduct.productPrice}</span>
                      <span className="red-text">({selectedProduct.discountValue}% OFF)</span>
                    </>
                  ) : (
                    <span className="blackcolor">$ {selectedProduct.productPrice}</span>
                  )}
                </div>


                <div className="product-price">
                  <div className="bortwo">

                  </div>


                  <p className='tex'><span> {selectedProduct.productDescription}</span></p>
                  <div className="product-color">
                    <h6 className="titlecolor">Color</h6>
                    <ul className="colorlist">
                      {productData && productData[0]?.productColorData.map((color, index) => (
                        <li
                          key={index}
                          style={{ backgroundColor: getcolorHexCode(color.colorName) || 'black' }}
                        ></li>
                      ))}
                    </ul>
                  </div>



                  {/* <div className="selsize"
                    value={selectedSize}
                    onChange={handleSizeChange}
                    style={{ maxWidth: '200px' }}
                  >
                    <option value="">Select Size:</option>
                    {availableSizes.map((size) => (
                      <option className="size-circle" key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </div> */}
                  <div className="product-size">
                    <ul className="size-list">
                      <h6 className="titlesize">Size</h6>
                      {availableSizes.map((size, index) => (
                        <li
                          key={index}
                          onClick={() => handleSizeClick(size)}
                          className={`size-item ${selectedSize === size ? 'selected-size' : ''}`}
                        >
                          {size}
                        </li>
                      ))}
                    </ul>

                  </div>
                  {!isSizeSelected && (
                    <p className="error-message">Please select a size before adding to the Bag.</p>
                  )}
                </div>



                <div class="product-quick-action">
                  <div className="qty-wrap">
                    <div className="pro-qty">
                      <input
                        type="number"
                        value={selectedQuantity}
                        onChange={handleQuantityChange}
                      />
                      <div className="dec qty-btn" onClick={handleDecrement}>
                        -
                      </div>
                      <div className="inc qty-btn" onClick={handleIncrement}>
                        +
                      </div>
                    </div>
                  </div>
                  <a class="btn-theme" onClick={handleAddToCart}>Add to Bag
                    {isInCart ? (
                      < LocalMallIcon className="added-to-cart" />
                    ) : (
                      <  ShoppingBagOutlinedIcon />

                    )}
                  </a>
                </div>
                <div class="product-wishlist-compare">
                  <NavLink >  <a className="newheartbtn" onClick={handleAddToWishlist}>

                    {isFavorite ? (
                      <FavoriteIcon className="filled-heart" style={{ color: 'red' }} />
                    ) : (
                      <FavoriteBorderIcon className="empty-heart" style={{ color: 'red' }} />
                    )}
                    Add To Wishlist </a></NavLink>


                </div>
                <div class="product-info-footer">
                  <p class="code"><span>Code :</span>{selectedProduct.productItemNo}</p>
                  <div class="social-iconss">
                    <span>Share</span>
                    <a href="#/"><i><FacebookIcon /></i></a>
                    <a href="#/"><i><InstagramIcon /></i></a>
                    <a href="#/"><i><TwitterIcon /></i></a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='cartsliderr'>
        <div className='container'>
          <div className="slidecartt">
            <h3>Releted Products</h3>
            <div className="tit-p">
              <p >There are many variations of passages of Lorem Ipsum available</p>
            </div>

            <Slider {...settings}>
              {data.slice(0, 5).map((value) => (
                <div class="best-slide">
                  <NavLink to={`/productdetail/${value._id}`}>
                    <img src={`https://${value.featuredProductImage}`} alt="img" />

                    <div class="best-p1-txtt">
                      <div className="name-of-p">
                        <p className="name-heart">
                          <span>{value.productBrandData[0].brandName}</span> </p>
                      </div>
                      <div class="pricee">
                        {value.productName}
                      </div>
                      <div className="price">
                        <span className="black-text">Rs. {value.productPrice}</span>
                        {/* <span className="old-price">Rs:{value.productDiscountedPrice}</span>
                        <span className="red-text">({value.discountValue}%OFF)</span> */}
                      </div>

                      <div className="buy-now">
                        <a href="#" >

                        </a>
                      </div>
                      <div className="producticons">
                        <span >
                          <i className="wishlisticon" onClick={() => addToWishlist(value, value.productBrandData[0]._id,)} title="Add to Wishlist">
                            <NavLink >
                              {relatedProductsFavorites[value._id] || isProductInWishlist(value._id) ? <FavoriteIcon /> : <FavoriteBorderIcon />}

                            </NavLink>
                          </i>
                        </span>
                        <span>
                          <i
                            className="wishlisticon"
                            title="Add to Cart"
                            onClick={() => addToCart(value, value.productBrandData[0]._id,)}
                          >
                            <NavLink >
                              {relatedProductsBags[value._id] || isProductInCart(value._id) ? <ShoppingBagIcon /> : <ShoppingBagOutlinedIcon />}
                            </NavLink>
                          </i>
                        </span>
                      </div>
                    </div>

                  </NavLink>
                </div>
              ))}
            </Slider>
          </div>

        </div>

      </section>


    </>
  )
}
export default ProductDetail