import React, { Component } from 'react';
import './Cart.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { NavLink, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function Cart() {
  const [data, setData] = useState([]);
  const [remove, setRemove] = useState([]);
  const [likedProducts, setLikedProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const navigate = useNavigate();
  const [guestCart, setguestCart] = useState({});
  
  // Function to fetch cart data from the API
  const fetchCartData = async () => {
    try {
      const token = getToken();
      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get("https://dev.ahura.xyz:3002/api/cart/user/getCart", config);
        setData(response.data.result.docs);
        console.log('Cart data from API:', response.data.result.docs);
      } else {
        const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
        setData(guestCart);
        console.log(guestCart);
      }
    } catch (error) {
      console.error('Error fetching cart:', error);
    }
  };

  useEffect(() => {
    fetchCartData();
  }, []);
  useEffect(() => {
  
    const calculatedSubtotal = 100; 
    setSubtotal(calculatedSubtotal); 
  }, []);

  // Function to add an value to the cart

  const handleDeleteClick = (valueId) => {
    const token = getToken();
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .delete(`https://dev.ahura.xyz:3002/api/cart/user/deleteProductFromCart/${valueId}`, config)
        .then((response) => {
          if (response.status === 200) {
            setData(data.filter(value => value._id !== valueId));
            console.log(response);
            toast.success('product remove from cart')
          } else {
            console.error('Failed to delete value from cart.');
          }
        })
        .catch((error) => {
          console.error('Error removing value from cart:', error);
        });
    } else {
      const updatedGuestCart = data.filter((item) => item._id !== valueId);
      localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
      setData(updatedGuestCart);
      toast.success('product remove from cart')
    }


  };

  const handleQuantityChange = (valueId, change) => {
    const updatedCart = data.map((item) => {
      if (item._id === valueId && item.productData && item.productData.length > 0) {
        const newQuantity = item.quantity + change;
        item.quantity = Math.max(newQuantity, 1); // Ensure quantity doesn't go below 1
        item.price = ((item.productData[0] && item.productData[0].productPrice) || item.productPrice || 0) * item.quantity;
      }
      return item;
    });
  
    console.log('Updated Cart:', updatedCart);
  
    setData(updatedCart);
  
    const newTotalPrice = calculateTotal(); // Use the calculateTotal function
  
    console.log('New Total Price:', newTotalPrice);
  
    setTotalPrice(newTotalPrice);
  };
  

  

  const handleLikeClick = (productId) => {

    const isLiked = likedProducts.includes(productId);

    if (isLiked) {

      setLikedProducts(likedProducts.filter(id => id !== productId));
    } else {

      setLikedProducts([...likedProducts, productId]);
    }

    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(`https://dev.ahura.xyz:3002/api/wishlist/user/addProductToWishlist`, { productId }, config)
        .then((response) => {
          // You can add a visual indicator that the product is added to the wishlist if needed.
          console.log('Product added to wishlist:', response.data);
        })
        .catch((error) => {
          console.error('Error adding product to wishlist:', error);
        });
    } else {
      console.log('User is not authenticated. Please log in to add values to the wishlist.');
    }
  };
  // useEffect(() => {
  //   if (data.length === 0) {
  //     // No values in the cart, set the total price to 0
  //     setTotalPrice(0);
  //   } else {
  //     const totalPrice = data.reduce((acc, value) => {
  //       return acc + value.price;
  //     }, 0);
  //     setTotalPrice(totalPrice);
  //   }
  // }, [data]);



  const handleCheckout = () => {
    try {
      navigate('/checkout');
    } catch (error) {
      console.error('Navigation error:', error);
    }
  };

  // useEffect(() => {
  //   const token = getToken();
  //   if (!token) {
  //     navigate('/login');
  //   } else {
  //     fetchCartData();
  //   }
  // }, [navigate]);


  const getToken = () => {
    const localToken = localStorage.getItem('token');
    const sessionToken = sessionStorage.getItem('token');

    return localToken || sessionToken; // Return token from either source
  };
  // const calculateTotal = () => {
  //   let totalPrice = 0;
  //   data.forEach((value) => {
  //     const productPrice = value.productData && value.productData.length > 0 ? value.productData[0].productPrice || 0 : value.productPrice || 0;
  //     totalPrice += productPrice * value.quantity;
  //   });
  //   return totalPrice.toFixed(2);
  // };
  const calculateTotal = () => {
    let totalPrice = 0;
    data.forEach((value) => {
      const productPrice = (
        value.productData && value.productData.length > 0
          ? value.productData[0].productPrice || 0
          : value.productPrice || 0
      );
      totalPrice += productPrice * value.quantity;
    });
    return totalPrice.toFixed(2);
  };
  
 
  
  
  
  



  return (
    <>
      <section className='regularbanner'>
        <div className="bannnercontent">
          <h2 className='bannertitle'>SHOPPING CART</h2>
          <NavLink> <a className='bannfirstlink'>Home </a><a className='bannlink'>/ SHOPPING CART </a></NavLink>
        </div>
      </section>
      <div className="container">
        {data.length > 0 ? (
          <div className="titlee"></div>
        ) : (
          <div >
            <h5 className="titlecart">
              Your Bag is empty.
              <NavLink to="/productlist">
                <div className="propbtn">
                  <button type="button">Continue Shopping</button>
                </div>
              </NavLink>
            </h5>

          </div>
        )}

        {data.length > 0 && (


          <div className="shipping-form-cart-totals">


            {/* <div className="checnkbtn">
              <a className="btn-theme btn-flat" onClick={handleCheckout} href="checkout">Proceed to checkout</a>
            </div> */}
          </div>
        )}
      </div>

      <section className='newcartpage'>
        <div className='container'>
          {data.length > 0 && (
            <div class="shopping-cart-form table-responsive">
              <form action="#" method="post">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th class="product-remove">&nbsp;</th>
                      <th class="product-thumb">&nbsp;</th>
                      <th class="productname">Product</th>
                      <th class="product-price">Price</th>
                      <th class="product-quantity">Quantity</th>
                      <th class="product-subtotal">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((value) => (
                      <tr class="cart-product-item" key={value._id}>
                        <td class="product-remove">
                          <span className='cartdelbtn' onClick={() => handleDeleteClick(value._id)}><DeleteIcon /></span>
                        </td>
                        <td class="product-thumb">
                          <NavLink to={`/productDetail/${value.productData && value.productData.length > 0 ? value.productData[0]._id : value._id}`}>
                            <img
                              src={`https://${value.productData && value.productData.length > 0 ? value.productData[0].featuredProductImage : value.featuredProductImage}`}
                              alt={value.productData && value.productData.length > 0 ? value.productData[0].productName : value.productName}
                              width={90}
                              height={90}
                            />
                          </NavLink>
                        </td>
                        <td class="productname">
                          <h4 class="titlcart">
                            <NavLink to={`/productdetail/${value.productData && value.productData.length > 0 ? value.productData[0]._id : value._id}`}>
                              {value.productData && value.productData.length > 0 ? value.productData[0].productName : value.productName}
                            </NavLink>

                          </h4>
                          <h4>
                            <NavLink >
                              {value.productSize}
                            </NavLink>

                          </h4>

                        </td>
                        <td class="product-price">
                          <span class="pricecart">${value.productData && value.productData.length > 0 ? value.productData[0].productPrice : value.productPrice}</span>
                        </td>
                        <td class="product-quantity">
                          <div class="proqty">
                            <input type="text" class="quantity" title="Quantity" value={value.quantity} />
                            <div class="dec qty-btn" onClick={() => handleQuantityChange(value._id, -1)}>-</div>
                           <div class="inc qty-btn" onClick={() => handleQuantityChange(value._id, 1)}>+</div>
                          </div>
                        </td>
                        <td class="product-subtotal">
                          <span class="price">${value.productData && value.productData.length > 0 ? value.productData[0].productPrice * value.quantity : value.productPrice}</span>
                        </td>
                      </tr>
                    ))}

                    <tr class="actions">
                      <td class="border-0" colspan="6">

                        <a href="/productlist" class="btn-theme btn-flat">Continue Shopping</a>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </form>
            </div>
          )}

          {data.length > 0 && (
            <div className="billingsec">
              <div class="row row-gutter-50">
                <div class="col-md-6 col-lg-4">

                </div>
                <div class="col-md-6 col-lg-4">

                </div>
                <div class="col-md-12 col-lg-4">
                  <div class="shipping-form-cart-totals">
                    <div class="section-title-cart">
                      <h5 class="title">Cart totals</h5>
                    </div>
                    <div class="cart-total-table">
                      <table class="table">
                        <tbody>
                          <tr class="cart-subtotal">
                            <td>
                              <p class="value" >Subtotal</p>
                            </td>
                            <td>
                              <p class="shipping-price">${calculateTotal()}</p>
                            </td>
                          </tr>
                          <tr class="shipping">
                            {/* <td>
                              <p class="value">Shipping</p>
                            </td> */}
                            <td>

                            </td>
                          </tr>
                          <tr class="order-total">
                            <td>
                              <p class="value">Total</p>
                            </td>
                            <td>
                              <p class="shipping-price">${calculateTotal()}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <a class="btntheme btn-flat" href="/checkout ">Proceed to checkout</a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>


  );


};

export default Cart;
