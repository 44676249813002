import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Header.css';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { NavLink } from 'react-router-dom'
import Mainlogo from '../../Images/logotwo.webp'
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';

import { useNavigate } from "react-router-dom";

const Header = () => {
  const [cartCount, setCartCount] = useState();
  const [wishlistCount, setWishlistCount] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      axios.get('https://dev.ahura.xyz:3002/api/cart/user/getCart', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((response) => {
          const cartData = response.data.result.docs || [];
          const totalCount = cartData.reduce((acc, item) => acc + item.quantity, 0);
          setCartCount(totalCount);
        })
        .catch((error) => {
          console.error('Error fetching counts:', error);
        });
    }
  },);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const isLoggedIn = localStorage.getItem('token');
  const isLoggedInSession = sessionStorage.getItem('token');
  const handleLogout = () => {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');


  };
  const handleLogin = () => {
    // Implement your login logic here and set isLoggedIn to true upon successful login
    isLoggedIn(true);
  }

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  const navigate = useNavigate();
  const handleLogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('token')
    localStorage.removeItem('type')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('type')
    navigate("/")
  }


  return (
    <>



      <nav>
        <div className="mainhd">
          <div className="container">
            <div className="small-header">

              <div className="sale-link">
                <p>World Wide Completely Free Returns and Free Shipping </p>
              </div>
              <div className="leftside">
                <div className="phone">
                  <i><LocalPhoneIcon /></i> <a>+00 123 456 789</a>
                </div>
                <div className="headbor"></div>
                <div className="headermail">
                  <i><EmailIcon /></i> <a > demo@example.com</a>
                </div>
                <div className="headbor"></div>
                <div className="account">
                  <div className="account-dropdown">
                    <i><PersonIcon /></i><a> Account</a>
                    <div className="dropdown-content">
                      {isLoggedIn || isLoggedInSession ? (
                        <>
                          <a href="/profile">My Profile</a>
                          <NavLink to="/logout" key="logout" className="userlink" id="logout" onClick={(e) => handleLogOut(e)} >
                            <div className="ussericon"></div>
                            <div className="user-link_text">Log Out</div>
                          </NavLink>
                        </>
                      ) : (
                        <>
                          <a href="/login" onClick={handleLogin}>Login</a>
                          <a href="/signup">Sign Up</a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container">

          <div className="main-nav">
            <div className="nav-item">
              <div className="nav-logo">
                <div className="head-logo">
                <img src={Mainlogo}   style={{ width: '131px', height: '34px' }} alt="" />
                </div>
              </div>
              <div className="nav-search">
                <div className="search">
                  <input  className="searchTerm" placeholder="search" />
                  <button className="searchbtn">
                    <i><SearchIcon /></i>
                  </button>
                </div>

              </div>

              <div className="nav-icons">
                <a href=""> <i className="hidden-search-icon" ><SearchIcon /></i>
                </a>

                <NavLink to='/wishlist' > <i><FavoriteBorderIcon /></i></NavLink>

                <NavLink to='/cart' className='newcount'>
                  <i><ShoppingBagOutlinedIcon /></i>
                  {cartCount > 0 && (
                    <span className="shop-count">
                      {cartCount}
                    </span>
                  )}

                </NavLink>

              </div>
              <button className={menuOpen ? "menu-toggler active" : "menu-toggler"} id='menu-toggler' type="button" onClick={toggleMenu}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </button>
            </div>
            <div className="navlinks">
              <div className="mlinks">
                <ul>
                  <li><NavLink exact to="/">HOME</NavLink></li>
                  <li><NavLink to="/productlist">PRODUCTS</NavLink></li>
                  <li><NavLink to="/about">ABOUT</NavLink></li>
                  <li><NavLink to="/contact">CONTACT US</NavLink></li>
                </ul>
              </div>
            </div>
            <div className={menuOpen ? "das-header mobile-nav-menu show" : "das-header mobile-nav-menu"} id="nav-menu">
              <div className="logo">
                <NavLink to="/">
                <img src={Mainlogo}   style={{ width: '171px', height: '34px',marginTop:'25px', objectFit: 'contain'  }} alt="" />

                </NavLink>
                <div className="mobile-menu">
                  <ul>
                  <ul>
                  <li><NavLink exact to="/">HOME</NavLink></li>
                  <li><NavLink to="/productlist">PRODUCTS</NavLink></li>
                  <li><NavLink to="/about">ABOUT</NavLink></li>
                  <li><NavLink to="/contact">CONTACT US</NavLink></li>
                </ul>
                  </ul>
                </div>
                <div className="menu-contact">
                  <div className="leftsidee">
                    <div className="phone">
                      <i><LocalPhoneIcon /></i> +00 123 456 789
                    </div>
                    <div className="headbor"></div>
                    <div className="headermail">
                      <i><EmailIcon /></i> demo@example.com
                    </div>
                    <div className="headbor"></div>
                    <div className="account">
                      <div className="account-dropdown">
                        <i><PersonIcon /></i> Account
                        <div className="dropdown-content">
                          {isLoggedIn ? (
                            <>
                              <a href="/profile">My Profile</a>
                              <a onClick={(e) => handleLogOut(e)}>Logout</a>
                            </>
                          ) : (
                            <>
                              <a href="/login" onClick={handleLogin}>Login</a>
                              <a href="/signup">Sign Up</a>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <button className={menuOpen ? "menu-toggler active" : "menu-toggler"} id='menu-close' type="button" onClick={closeMenu}>
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </button>
              </div>



            </div>
          </div>
        </div>

      </nav>



    </>
  )
}
export default Header