import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './EditProfile.css'
function EditProfile() {
  const [formDatas, setFormDatas] = useState({
    userName: '',
    lastName: '',
    userMobileNo: '',
    dateOfBirth: '',
    userGender: 1,
  });
  const token = localStorage.getItem('token');
  const sessionToken = sessionStorage.getItem('token');
  const isLoggedIn = !!token || !!sessionToken; // Consider using both tokens if either suffices for login

  const navigate = useNavigate();
  // ... (existing code remains the same)

  useEffect(() => {
    if (isLoggedIn) {
      const fetchData = async () => {
        try {
          const response = await axios.patch(
            'https://dev.ahura.xyz:3002/api/user/userDetails',
            null,
            {
              headers: {
                Authorization: `Bearer ${token || sessionToken}`, // Use either token if available
              },
            }
          );

          if (response.status === 200) {
            const userData = response.data.user;

            setFormDatas({
              userName: userData.userName,
              lastName: userData.lastName,
              userMobileNo: userData.userMobileNo,
              dateOfBirth: userData.dateOfBirth,
              userGender: userData.userGender,
            });
          } else {
            console.log('No user data available.'); // Or display a message to the user
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
          if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
          }
        }
      };

      fetchData();
    }
  }, [isLoggedIn, token, sessionToken]);

  // ... (existing code remains the same)




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDatas({
      ...formDatas,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const localStorageToken = localStorage.getItem('token');
    const sessionStorageToken = sessionStorage.getItem('token');
    const token = localStorageToken || sessionStorageToken;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .put('https://dev.ahura.xyz:3002/api/user/updateUserDetails', formDatas, config)
      .then((response) => {
        console.log('Profile updated successfully', response.data.user);
        navigate('/profile');
      })
      .catch((error) => {
        console.error('Error updating profile:', error);
      });
  };
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login'); // Use navigate to redirect to the login page
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      <section className="sec-edit">
        <div class="container">
          <div className="tit-prop">
            <h4>Profile Edit</h4>
          </div>
          <div className="borderline"></div>

          <div className="inner-form">
            <form>
              <div className="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <label class="profile_details_text">First Name:</label>
                    <input
                      type="text"
                      name="userName"
                      class="form-control"
                      value={formDatas.userName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>


                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <label class="profile_details_text">Last Name:</label>
                    <input
                      type="text"
                      name="lastName"
                      class="form-control"
                      value={formDatas.lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="profile_details_text">Mobile Number:</label>
                    <input
                      type="tel"
                      name="userMobileNo"
                      class="form-control"
                      value={formDatas.userMobileNo}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="profile_details_text">Date Of Birth:</label>
                    <input
                      type="text"
                      name="dateOfBirth"
                      class="form-control"
                      placeholder='Date of Birth(DD.MM.YY)'
                      value={formDatas.dateOfBirth}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="profile_details_text">Gender:</label>
                    <select
                      name="userGender"
                      class="form-control"
                      value={formDatas.userGender}
                      onChange={handleChange}
                      required
                    >
                      <option value={1}>Male</option>
                      <option value={2}>Female</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>

            <div className="propbtn">
              <button onClick={handleSubmit}>Save Change</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EditProfile;
