
import { useState } from 'react';
import './About.css';
import { NavLink, } from 'react-router-dom';
import aboutimg from '../../Images/aboutimg.webp';
import teamone from '../../Images/teamone.webp';
import teamtwo from '../../Images/teamtwo.webp';
import teamthree from '../../Images/teamthree.webp';
import teamfour from '../../Images/teamfour.webp';

function About() {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <section className='regularbanner'>
        <div className="bannnercontent">
          <h2 className='bannertitle'>About Us</h2>
          <NavLink> <a href="" className='bannfirstlink'>Home  </a><a href="" className='bannlink'> / About Us</a></NavLink>


        </div>
      </section>
      <section class="about-area about-default-wrapper">
        <div class="container">
          <div class="about-item position-relative">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="about-thumb">
                  <div
                    className="shape-one scene"
                    style={{
                      transform: 'translate3d(0px, 0px, 0px)',
                      transformStyle: 'preserve-3d',
                      backfaceVisibility: 'hidden',
                      position: 'relative',
                    }}
                  >
                    <span
                      className="scene-layer"
                      data-depth=".2"
                      style={{
                        transform: isHovered
                          ? 'translate3d(-10px, -10px, 0px)' // Your hover transform values
                          : 'translate3d(-1.77814%, 0.0719794%, 0px)', // Your default transform values
                        transformStyle: 'preserve-3d',
                        backfaceVisibility: 'hidden',
                        position: 'relative',
                        display: 'block',
                        height: '100%',
                        width: '100%',
                        left: '0px',
                        top: '0px',
                      }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <img src={aboutimg} width="570" height="368" alt="Image-HasTech" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="about-content">
                  <h4 class="sub-title">Smart Life</h4>
                  <h3 class="titlee">With Smart Shoes</h3>
                  <p class="desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmol tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minivel quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidata non proident, sunt in culpa qui officia.</p>
                  <a class="btn-about" href="contact.html">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* team */}
      <section class="team-area">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title text-center">
                <h3 class="titleone">Our Team</h3>
                <div class="desc">
                  <p>There are many variations of passages of Lorem Ipsum available</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-lg-3">

              <div class="team-item">
                <div class="inner-content">
                  <div class="thumb">
                    <a href="about-us.html"><img src={teamone} class="img-fluid" alt="Image-HasTech" /></a>

                  </div>
                  <div class="contentteam">
                    <h4 class="titleteam"><a href="about-us.html">Jander Durham</a></h4>
                    <p>Team Member</p>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-6 col-lg-3">

              <div class="team-item">
                <div class="inner-content">
                  <div class="thumb">
                    <a href="about-us.html"><img src={teamtwo} class="img-fluid" alt="Image-HasTech" /></a>

                  </div>
                  <div class="contentteam">
                    <h4 class="titleteam"><a href="about-us.html">Jaren Hammer</a></h4>
                    <p>Team Member</p>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-6 col-lg-3">

              <div class="team-item">
                <div class="inner-content">
                  <div class="thumb">
                    <a href="about-us.html"><img src={teamthree} class="img-fluid" alt="Image-HasTech" /></a>

                  </div>
                  <div class="contentteam">
                    <h4 class="titleteam"><a href="about-us.html">Nurdan Denkel</a></h4>
                    <p>Team Member</p>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-6 col-lg-3">

              <div class="team-item">
                <div class="inner-content">
                  <div class="thumb">
                    <a href="about-us.html"><img src={teamfour} class="img-fluid" alt="Image-HasTech" /></a>

                  </div>
                  <div class="contentteam">
                    <h4 class="titleteam"><a href="about-us.html">Monroe Bond</a></h4>
                    <p>Team Member</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default About