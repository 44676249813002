import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { NavLink, } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Login = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    userMobileNo: '',
    password: '',
  });

  const [stayLoggedIn, setStayLoggedIn] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStayLoggedInChange = (e) => {
    setStayLoggedIn(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://dev.ahura.xyz:3002/api/user/userLogin', formData);
      console.log('Login successful!', response.data);
      toast.success('Login successful!', {

      });

      const { token } = response.data;

      if (stayLoggedIn) {
        localStorage.setItem('token', token);
      } else {
        sessionStorage.setItem('token', token);
      }

      setTimeout(() => {
        navigate('/profile');
        toast.dismiss(); // Dismiss the toast when navigating
      }, 2000); // Delay the navigation for 3000 milliseconds (3 seconds)


      setFormData({
        userMobileNo: '',
        password: '',
      });
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Error logging in. Please try again.', {
        // Toast configurations for error
      });
    }
  };


  return (
    <>
      <section className='regularbanner'>
        <div className="bannnercontent">
          <h2 className='bannertitle'>LOGIN</h2>
          <NavLink> <a href="/" className='bannfirstlink'>Home </a><a href="" className='bannlink'>/ LOGIN </a></NavLink>


        </div>
      </section>
      <div className="login">
        <h2>Login</h2>
        <div className="borderlogin"></div>


        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="userMobileNo">Mobile Number
              <span class="required">*</span>
            </label>
            <input
              type="tel"
              id="userMobileNo"
              name="userMobileNo"
              value={formData.userMobileNo}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password
              <span class="required">*</span>
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>


          <button type="submit" className='submitlogin'>Login</button>
        </form>
        <div className="loginflex">
          <div className="form-groupp">
            <label className='remeberme'>
              <input
                type="checkbox"
                name="stayLoggedIn"
                checked={stayLoggedIn}
                onChange={handleStayLoggedInChange}
              />

              Remember me

            </label>

          </div>
          <div className="forpass">
            <p className="forgotpass">
              <Link to="/forgotpass" className="linkk">
                Lost your password?
              </Link>
            </p>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover

        />
      </div>
    </>
  );
};

export default Login;
