import { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';
import './WishList.css'
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function WishList() {
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [cartItems, setCartItems] = useState({});
  const [isSizeSelected, setIsSizeSelected] = useState(true);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [selectedSize, setSelectedSize] = useState('');
  const fetchWishlistData = () => {
    const token = getToken();

    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .get("https://dev.ahura.xyz:3002/api/wishlist/user/getWishlist", config)
        .then((response) => {
          setData(response.data.result.docs); // Update wishlist data state
          console.log('Wishlist data from API:', response.data.result.docs); // Log received data
        })
        .catch((error) => {
          console.error('Error fetching wishlist from API:', error);
        });
    } else {
      const guestWishlist = JSON.parse(localStorage.getItem('guestWishlist')) || [];
      setData(guestWishlist);
      console.log('Guest wishlist from local storage:', guestWishlist); // Log local storage data
    }
  };


  const handleRemoveFromWishlist = (itemId) => {
    const token = getToken();

    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .delete(`https://dev.ahura.xyz:3002/api/wishlist/user/deleteProductFromWishlist/${itemId}`, config)
        .then(() => {
          // Remove the item from the local state (data) to reflect the removal
          setData(data.filter(item => item._id !== itemId));

          // Remove the item from the wishlistItems state
          setWishlistItems(wishlistItems.filter(item => item !== itemId));
          toast.success('product remove from cart')

          // Update the local storage with the updated wishlist
          localStorage.setItem('wishlist', JSON.stringify(wishlistItems));
          sessionStorage.setItem('wishlist', JSON.stringify(wishlistItems));
        })
        .catch((error) => {
          console.error('Error removing item from wishlist:', error);
        });
    }
    else {
      const guestWishlist = JSON.parse(localStorage.getItem('guestWishlist')) || [];
      const updatedGuestWishlist = guestWishlist.filter(item => item._id !== itemId);
      localStorage.setItem('guestWishlist', JSON.stringify(updatedGuestWishlist));

      toast.success('product remove from cart')
      setData(updatedGuestWishlist);
    }
  };


  const wishlistCount = data.length;

  const navigate = useNavigate();
  useEffect(() => {

    fetchWishlistData(); // Call your function to fetch wishlist data here
  }, []);

  const getToken = () => {
    const localToken = localStorage.getItem('token');
    const sessionToken = sessionStorage.getItem('token');

    return localToken || sessionToken; // Return token from either source
  };


  const addToCart = async (value, productId, productBrandId, productSize) => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');

    if (token) {
      // User is logged in, proceed with API call
      const data = {
        brandId: value?.brandData[0]._id,
        productId: value.productData[0]._id,
        productSize: value.productData[0].productSize[0],
        quantity: 1,
      };
      console.log('if')
      try {
        const response = await axios.post('https://dev.ahura.xyz:3002/api/cart/user/addProductToCart', data, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });

        if (response.status === 200) {
          toast.success('Product added to the cart successfully');
          const newItem = {
            brandId: value?.brandData[0]._id,
            productId: value.productData[0]._id,
            productSize: value.productSize[0],
            quantity: 1,
          };
          setCartItems(prevItems => ({
            ...prevItems,
            [productId]: newItem
          }));
        } else {
          console.log('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error adding the product to the cart:', error);
      }
    } else {
      // User is not logged in, add product to cart using local storage

      console.log('else', value)
      // Retrieve guest cart from local storage
      const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
      guestCart.push({
        ...value,
        quantity: 1,
        productSize: value.productSize.length ?? value.productSize[0].length > 0 ? value.productSize : '',
      });

      // Update guest cart in local storage
      localStorage.setItem('guestCart', JSON.stringify(guestCart));
      toast.success('Product added to cart');
      // Inform the user (Optional)
      console.log('Product added to guest cart');
    }
  };





  return (
    <>
      <section className='regularbanner'>
        <div className="bannnercontent">
          <h2 className='bannertitle'>WISHLIST</h2>
          <NavLink> <a href="/" className='bannfirstlink'>Home </a><a href="" className='bannlink'>/ WISHLIST </a></NavLink>
        </div>
      </section>

      <section class="shopping-wishlist-area">
        <div className="container">
          <div className="row">
            {data.length > 0 ? (
              <div className="backtohome">
                <a href="/">My Wishlist Items({wishlistCount})</a>
              </div>
            ) : (
              <div >
              </div>

            )}
          </div>
        </div>
        <div class="container">
          <div class="row">

            <div class="col-md-12" >
              <div class="shopping-wishlist-table table-responsive">
                <table class="table text-center">
                  {data.length > 0 ? (
                    <thead id="wishlistHeader">
                      <tr>
                        <th class="product-remove">&nbsp;</th>
                        <th class="product-name">Product</th>
                        <th class="product-thumb"></th>

                        <th class="product-stock-status"></th>
                        <th class="product-price">Price</th>
                        <th class="product-action">&nbsp;</th>
                      </tr>
                    </thead>
                  ) : (
                    <div >
                      <h5 className="titlecart">
                        Your Wishlist is empty.
                        <NavLink to="/productlist">
                          <div className="propbtn">
                            <button type="button">Continue Shopping</button>
                          </div>
                        </NavLink>
                      </h5>

                    </div>
                  )}

                  <tbody >
                    {data.map((value) => {
                      return (
                        <tr className="cart-wishlist-item" key={value._id}>
                          <td className="product-remove">
                            <a className='delicons' href="#/">
                              <i onClick={() => handleRemoveFromWishlist(value._id)}>
                                <DeleteIcon />
                              </i>
                            </a>
                          </td>

                          <td className="productwishthumb">
                            <NavLink to={`/productDetail/${value.productData && value.productData.length > 0 ? value.productData[0]._id : value._id}`}>
                              <img
                                src={`https://${value.productData && value.productData.length > 0 ? value.productData[0].featuredProductImage : value.featuredProductImage}`}
                                alt={value.productData && value.productData.length > 0 ? value.productData[0].productName : value.productName}
                                width={90}
                                height={90}
                              />
                            </NavLink>
                          </td>
                          <td className="product-name">
                            <p className="priwishlist">
                              {value.productData && value.productData.length > 0 ? value.productData[0].productName : value.productName}
                            </p>
                          </td>
                          <td>
                            {/* Your condition for rendering */}
                          </td>
                          <td className="product-price">
                            <span className="priwishlist">
                              ${value.productData && value.productData.length > 0 ? value.productData[0].productPrice : value.productPrice}
                            </span>
                          </td>
                          <td className="product-action">
                            <a className="btn-cart" onClick={() => addToCart(value)}
                            >
                              Add to cart
                            </a>
                          </td>
                        </tr>
                      );
                    })}

                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}

export default WishList
