import React, { useState, useEffect } from 'react';
import './Profile.css';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import { NavLink, useNavigate } from 'react-router-dom';

function Profile() {
  const [userProfile, setUserProfile] = useState({
    userName: '',
    userMobileNo: '',
    userGender: '',
    dateOfBirth:'',
    lastName:'',
  });

  const navigate = useNavigate();
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  const isLoggedIn = !!token; // Check if the user is logged in

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        try {
          const response = await axios.patch('https://dev.ahura.xyz:3002/api/user/userDetails', null, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) {
            setUserProfile(response.data.user);
          } else {
            console.log('No user data available.'); // Or display a message to the user
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
          if (error.response) {
            console.error('Response data:', error.response.data.user);
            console.error('Response status:', error.response.status);
          }
        }
      };

      fetchData();
    }
  }, [token]);

  const getGenderText = (genderCode) => {
    if (genderCode === 1) {
      return 'Male';
    } else if (genderCode === 2) {
      return 'Female';
    }
    return 'Other';
  };
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login'); // Use navigate to redirect to the login page
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="profile-details">
      <div className="tit-prop">
        <h4>Account Details</h4>
        <NavLink to='/editprofile'> <EditIcon className='newicon' /></NavLink>
      </div>
      <div className='borderline'></div>
      <table>
        {token ? (
          <>
            <tr>
              <td>Name:</td>
              <td>{userProfile.userName} {userProfile.lastName}</td>
            </tr>
            <tr>
              <td>Mobile Number:</td>
              <td>{userProfile.userMobileNo}</td>
            </tr>
            <tr>
              <td>Gender:</td>
            <td>{getGenderText(userProfile.userGender)}</td>
            </tr>
            <tr>
              <td>Date of Birth:</td>
              <td>{userProfile.dateOfBirth}</td>
            </tr>
          </>
        ) : (
          <tr>
            <td colSpan="2">Please log in to view your profile.</td>
          </tr>
        )}
      </table>
    </div>
  );
}

export default Profile;
