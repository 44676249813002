import React, { useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';

function ForgotpassOtp() {
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleOtpChange = (e) => {
    setCode(e.target.value);
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    try {
      // Verify OTP
      const otpRegex = /^\d{4}$/;
      if (!otpRegex.test(code)) {
        setErrorMessage('Invalid OTP format. Please enter a 4-digit OTP.');
        return;
      }

      const requestData = { code };
      const response = await axios.post('http://192.168.1.20:3000/api/user/otp/verifyOtp', requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response) {
        setErrorMessage('');
        navigate(`/resetpass`);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Error verifying OTP. Please try again.');
      }
    }
  };

  return (
    <>
      <section className='regularbanner'>
    <div className="bannnercontent">
        <h2 className='bannertitle'>verify otp</h2>
         <NavLink> <a href="/" className='bannfirstlink'>Home </a><a href="" className='bannlink'>/ verify otp </a></NavLink>
      </div>
  </section>
      <div className='forgot-password'>
        <h2>Verify OTP</h2>
        <div className="borderlogin"></div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <form onSubmit={handleVerifyOtp}>
          <div className="form-group">
            <label htmlFor="otp">
              Enter OTP <span className="required">*</span>
            </label>
            <input
              type="text"
              id="otp"
              name="otp"
              value={code}
              onChange={handleOtpChange}
              required
            />
          </div>
          
          <button type="submit" className='submitlogin'>Verify OTP</button>
        </form>
      </div>
    </>
  );
}

export default ForgotpassOtp;
