import React, { useState, useEffect } from 'react';
import './ProductList.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import TuneIcon from '@mui/icons-material/Tune';
import SortIcon from '@mui/icons-material/Sort';
import { NavLink, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import $ from 'jquery';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useCart } from '../CartContext/CartContext';

const ProductList = ({ productsData }) => {
  // State to store the fetched data
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [prices, setPrices] = useState([]);
  const [Category, setCategory] = useState([]);
  const [visibleBrands, setVisibleBrands] = useState([]); // State to track visible brands
  const [showAllBrands, setShowAllBrands] = useState(false); // State to track whether to show all brands
  const [showSaleOnly, setShowSaleOnly] = useState(false);
  const [showAllColors, setShowAllColors] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [showFilters, setShowFilters] = useState(true);
  const [selectedSize, setSelectedSize] = useState('');
  const [showProducts, setShowProducts] = useState(false);
  const [wishlistItems, setWishlistItems] = useState({});
  const [cartItems, setCartItems] = useState({});
  const [isFavorite, setIsFavorite] = useState(false);
  const [guestWishlistItems, setGuestWishlistItems] = useState({});
  const [isInCart, setIsInCart] = useState(false);



  const navigate = useNavigate();
  const [sizes, setSizes] = useState([
    { _id: 'productSize', sizeName: 'EU-36' },
    { _id: 'EU-41', sizeName: 'EU-37' },
    { _id: 'EU-42', sizeName: 'EU-38' },
    { _id: 'EU-43', sizeName: 'EU-39' },
    { _id: 'EU-44', sizeName: 'EU-40' },
    { _id: 'EU-45', sizeName: 'EU-41' },
    { _id: 'EU-45', sizeName: 'EU-42' },
    { _id: 'EU-45', sizeName: 'EU-43' },
    { _id: 'EU-45', sizeName: 'EU-44' },
    { _id: 'EU-45', sizeName: 'EU-45' },
    { _id: 'EU-45', sizeName: 'EU-46' },

  ]);


  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };

  const handleViewMoreClick = () => {
    setShowAllBrands(!showAllBrands);
  };
  // api for cart
  const [data, setdata] = useState([])

  useEffect(() => {
    axios
      .get(
        `https://dev.ahura.xyz:3002/api/product/user/filter/?${selectedCategory ? `productCategory=${selectedCategory}` : ''
        }${selectedBrand ? `&productBrand=${selectedBrand}` : ''
        }${selectedGender ? `&productGender=${selectedGender}` : ''
        }${(selectedCategory || selectedBrand || selectedGender) && `&onsale=2`
        }${showSaleOnly && `&onsale=2`
        }${selectedColor ? `&color=${selectedColor}` : ''
        }${selectedSize ? `&size=${selectedSize}` : ''
        }`
      )



      .then((response) => {
        setdata(response.data.result.docs);
        console.log(response);
      });

  }, [selectedBrand, selectedCategory, selectedGender, selectedSize]);


  // Function to fetch data from the API for checkbox
  const fetchData = async () => {
    try {
      const response = await fetch('https://dev.ahura.xyz:3002/api/product/user/filterData');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);
      setBrands(data.filterElements.brands);
      setColors(data.filterElements.color);
      setPrices(data.filterElements.prices);
      setCategory(data.filterElements.category);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    // Update the visible brands based on whether to show all brands
    if (showAllBrands) {
      setVisibleBrands(brands);
    } else {
      // Show only a limited number of brands (e.g., first 5)
      setVisibleBrands(brands.slice(0, 4));
    }
  }, [brands, showAllBrands]);
  const handleViewMoreCategoriesClick = () => {
    setShowAllCategories(!showAllCategories);
  };
  useEffect(() => {
    // Update the visible categories based on whether to show all categories
    if (showAllCategories) {
      setVisibleCategories(Category);
    } else {
      // Show only a limited number of categories (e.g., first 5)
      setVisibleCategories(Category.slice(0, 4));
    }
  }, [Category, showAllCategories]);

  document.addEventListener('click', function (event) {
    if (event.target.classList.contains('filterr-button')) {
      // Implement filter logic here
    }
  });


  //  pagination
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 15;

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = data.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(data.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });


  };
  const handleCategoryChange = (category) => {
    // Your logic to handle category change...

    // Reset pagination to page 1 when category changes
    setCurrentPage(1);
  };

  const handleFilterChange = (filter) => {
    // Your logic to handle filter change...

    // Reset pagination to page 1 when filter changes
    setCurrentPage(1);
  };
  // list cart
  const toggleProducts = () => {
    setShowProducts(!showProducts);
  };
  // filter sidebar

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleResetFilters = () => {
    setSelectedGender('');
    setSelectedCategory('');
    setSelectedBrand('');
    setShowSaleOnly(false);
    setSelectedColor('');
    setSelectedSize('');
  };
  const { wishlistData, updateWishlist,increaseCount,increaseCountforbag } = useCart()

  const [relatedProductsBags, setRelatedProductsBags] = useState({});

  const addToCart = async (value, productId, productBrandId, productSize) => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    console.log('Adding to cart. Product ID:', productId);
    
    if (token) {
      const data = {
        brandId: value.productBrandData[0]._id,
        productId: value._id,
        productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
        quantity: 1      
      };
      
      try {
        const response = await axios.post('https://dev.ahura.xyz:3002/api/cart/user/addProductToCart', data, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        if (response) {
          toast.success('Product added to the cart successfully');
          setRelatedProductsBags(prevState => ({
            ...prevState,
            [value._id]: true,
          }));
          
          increaseCountforbag();
         
          setIsInCart(true);
          console.log('Cart Items:', cartItems);
        } else {
          console.log('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error adding the product to the cart:', error);
      }
    } else {

      console.log('else', value)
      // Retrieve guest cart from local storage
      const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
      guestCart.push({
        ...value,
        quantity: 1,
        productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
      });

      // Update guest cart in local storage
      localStorage.setItem('guestCart', JSON.stringify(guestCart));
      toast.success('Product added to the cart successfully');
      
    }
  };
  const isProductInCart = (productId) => {
    if (Object.keys(cartItems).length > 0) {
      return productId in cartItems;
    } else {
      const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
      return guestCart.some(item => item._id === productId);
    }

  };
  const [relatedProductsFavorites, setRelatedProductsFavorites] = useState({});
  const [addedToWishlisticon, setAddedToWishlisticon] = useState([]);


  const isProductInWishlist = (productId) => {
    if (wishlistData.length > 0) {
      return wishlistData.some(item => item.productData.some(product => product._id === productId));
    } else {
      const guestWishlist = JSON.parse(localStorage.getItem('guestWishlist')) || [];
      return guestWishlist.some(item => item._id === productId);
    }
  };

  const createProductData = (value) => {
    return {
      brandId: value.productBrandData[0]._id,
      productId: value._id,
      productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
      quantity: 1
    };
  };

  const addToWishlist = async (value) => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const data = createProductData(value);
  
    if (token) {
      // User is logged in, check if the product is in the wishlistData
      if (isProductInWishlist(value._id)) {
        toast.warning('Product is already in the wishlist');
        return;
      }
  
      try {
        const response = await axios.post(
          'https://dev.ahura.xyz:3002/api/wishlist/user/addProductToWishlist',
          data,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }
        );
        console.log('Before adding to wishlist:', relatedProductsFavorites);

        if (response) {
          toast.success('Product added to the wishlist successfully');
          setRelatedProductsFavorites(prevState => ({
            ...prevState,
            [value._id]: true,
          }));
          setAddedToWishlisticon(prevState => [...prevState, value._id]);
          increaseCount();
          updateWishlist();
        } else {
          console.log('Unexpected response structure:', response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          toast.warning('somthing is wrong, try again later')
          // setRelatedProductsFavorites(prevState => ({
          //   ...prevState,
          //   [value._id]: true,
          // }));
          // setAddedToWishlisticon(prevState => [...prevState, value._id]);
          // console.error('Conflict while adding to wishlist:', error.response.message);
         
        } else {
          console.error('Error adding to wishlist:', error);
        }
      }
    } else {
      if (isProductInWishlistForGuest(value._id)) {
        toast.warning('Product is already in the wishlist');
        return;
      }
  
      // Fetch the guest wishlist from local storage
      let guestWishlist = JSON.parse(localStorage.getItem('guestWishlist') || '[]');
  
      guestWishlist.push({
        ...value,
        quantity: 1,
        productSize: value.productSize.length > 0 ? value.productSize[0] : 'DefaultSize',
      });
        localStorage.setItem('guestWishlist', JSON.stringify(guestWishlist));
  
      toast.success('Product added to the wishlist successfully');
    }
  };
  
  // Helper function for checking wishlist for guest users
  const isProductInWishlistForGuest = (productId) => {
    const guestWishlist = JSON.parse(localStorage.getItem('guestWishlist')) || [];
    return guestWishlist.some(item => item._id === productId);
  };
  


  return (
    <>
      <section className='regularbanner'>
        <div className="bannnercontent">
          <h2 className='bannertitle'>PRODUCT PAGE</h2>
          <NavLink> <a href="" className='bannfirstlink'>Home </a><a href="" className='bannlink'>/  PRODUCT PAGE</a></NavLink>


        </div>
      </section>



      {/* newsidebar */}
      <section class="product-area product-default-area">
        <div class="container">
          <div class="row flex-xl-row-reverse justify-content-between">
            <div class="col-xl-9">
              <div class="row">
                <div class="col-12">
                  <div class="shop-top-bar">
                    <div class="shop-top-left">
                      <p class="pagination-line"><a href="shop.html">15</a> Product Found of <a href="shop.html">25</a></p>
                    </div>
                    <div class="searchh">
                      <span class="iconssearch"><i><SearchIcon /></i></span>
                      <input placeholder="Search" />
                    </div>

                    <div class="shop-top-center">
                      <nav class="product-nav">
                        <button className={menuOpen ? "menuntoggler active" : "menuntoggler"} id='menutoggler' type="button" onClick={toggleMenu}>
                          {/* <span className="line"></span>
                          <span className="line"></span>
                          <span className="line"></span> */}
                          <i><TuneIcon style={{ fontSize: '26px' }} /></i>
                        </button>
                      </nav>
                    </div>
                    <div class="shop-top-right">
                      <div class="shop-sort">

                        <select class="form-select" aria-label="Sort select example">
                          <option selected="">Sort By</option>
                          <option value="1">Popularity</option>
                          <option value="2">Average Rating</option>
                          <option value="3">Newsness</option>
                          <option value="4">Price Low to High</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-grid" role="tabpanel" aria-labelledby="nav-grid-tab">
                      <div class="row">
                        {
                          currentProducts.map((value) => {
                            return (
                              <div class="col-sm-6 col-lg-4">
                                <NavLink to={`/productdetail/${value._id}`}>
                                  <div class="product-item">
                                    <div class="inner-content">
                                      <div class="product-thumb">
                                        {value.productOnsale === 2 && (
                                          <div class="sale-label">
                                            <p>-{value.discountValue}%</p>
                                          </div>
                                        )}
                                        <img src={`https://${value.featuredProductImage}`} style={{ width: '270px', height: '274px' }} alt="Image-HasTech" />

                                        <div className="producticonscart">

                                          <span>
                                            <i
                                              className="wishlisticon"
                                              onClick={() => addToWishlist(value, value.productBrandData[0]._id)}
                                              title="Add to Wishlist"
                                            >
                                              <NavLink to='/productlist'>
                                              {relatedProductsFavorites[value._id] || isProductInWishlist(value._id) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                              </NavLink>
                                            </i>
                                          </span>
                                          <span>
                                            <i
                                              className="wishlisticon"
                                              title="Add to Cart"
                                              onClick={() => addToCart(value, value.productBrandData[0]._id)}
                                            >
                                              <NavLink to='/productlist'>
                                                {relatedProductsBags[value._id] || isProductInCart(value._id) ? <ShoppingBagIcon /> : <ShoppingBagOutlinedIcon />}
                                              </NavLink>
                                            </i>
                                          </span>

                                        </div>

                                      </div>
                                      <div class="product-info">
                                        <div class="category">
                                          <ul>
                                            <li>{value.productBrandData[0]?.brandName}</li>

                                          </ul>
                                        </div>
                                        <div class="priceeone">
                                          {value.productName}
                                        </div>

                                        {value.productDiscountedPrice && (
                                          <div className="sidebarprice">
                                            <span className="black-text">Rs.{value.productPrice}</span>
                                            <span className="old-price">Rs.{value.productDiscountedPrice}</span>
                                            <span className="red-text">({value.discountValue}% OFF)</span>
                                          </div>
                                        )}
                                        {/* Always display the regular price */}
                                        {!value.productDiscountedPrice && (
                                          <div className="sidebarprice">
                                            <span className="black-text">Rs.{value.productPrice}</span>
                                          </div>
                                        )}

                                      </div>
                                    </div>
                                  </div>
                                </NavLink>
                              </div>

                            );
                          })
                        }



                        <div class="col-12">
                          <div class="pagination-items">
                            <ul class="pagination justify-content-end mb--0">
                              {/* Previous Button */}
                              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                  <ArrowBackIcon />
                                </button>
                              </li>

                              {/* Current Page Button */}
                              {
                                Array.from({ length: Math.min(totalPages, 3) }, (_, i) => i + currentPage).map((page) => (
                                  <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(page)}>
                                      {page}
                                    </button>
                                  </li>
                                ))
                              }

                              {/* Next Button */}
                              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                  <ArrowForwardIcon />
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>


                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="shop-sidebar">
                <div className="resetbtn">
                  <a onClick={handleResetFilters}> Reset Filter</a>
                </div>
                <div class="shop-sidebar-category">
                  <h4 class="sidebar-title">Shoes</h4>
                  <div class="sidebar-category">
                    <ul class="category-list mb--0">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          name="filter"
                          checked={selectedGender === '1'}
                          onChange={() => setSelectedGender('1')}
                        />
                        Men
                      </label>

                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          name="filter"
                          checked={selectedGender === '2'}
                          onChange={() => setSelectedGender('2')}
                        />
                        Women
                      </label>

                      <label className="checkbox-label">

                        <input type="checkbox" name="filter" />
                        Kid's
                      </label>
                    </ul>
                  </div>
                </div>
                <div class="shop-sidebar-size">
                  <h4 class="sidebar-title">Category</h4>
                  <div class="sidebar-size">
                    <ul class="size-list">
                      {visibleCategories && visibleCategories.length > 0 ? (
                        visibleCategories.map((category) => (
                          <label key={category.id} className="checkbox-label">
                            <input
                              type="checkbox"
                              // name="filter"
                              checked={selectedCategory === category._id}
                              onChange={() => setSelectedCategory(category._id)}
                            />
                            {category.categoryName}
                          </label>
                        ))
                      ) : (
                        <p>Loading...</p>
                      )}
                      <div className='viewmore'>
                        {Category.length > 3 && (
                          <a onClick={handleViewMoreCategoriesClick}>
                            {showAllCategories ? 'Show Less ' : 'View More+'}
                          </a>
                        )}
                      </div>
                    </ul>
                  </div>
                </div>
                <div class="shop-sidebar-brand">
                  <h4 class="sidebar-title">Brand</h4>
                  <div class="sidebar-brand">
                    <ul class="brand-list mb--0">
                      {visibleBrands && visibleBrands.length > 0 ? (
                        visibleBrands.map((brand) => (
                          <label
                            key={brand.id}
                            className="checkbox-label"
                          >
                            <input
                              type="checkbox"
                              // name="filter"
                              className={selectedBrand === brand._id}
                              onChange={() => setSelectedBrand(brand._id)}
                            />

                            {brand.brandName}
                          </label>
                        ))
                      ) : (
                        <p>Loading...</p>
                      )}
                      <div className='viewmore'>
                        {brands.length > 4 && (
                          <a onClick={handleViewMoreClick}>
                            {showAllBrands ? 'Show Less' : 'View More+'}
                          </a>
                        )}
                        {/* ... Render other filters */}
                      </div>
                    </ul>
                  </div>
                </div>
                <br />

                <div class="shop-sidebar-price-range">
                  <h4 class="sidebar-title">Price Range</h4>
                  <div class="sidebar-price-range">
                    <label className="checkbox-label">

                      <input type="checkbox" name="filter" />
                      ₹500-1000
                    </label>
                    <label className="checkbox-label">

                      <input type="checkbox" name="filter" />
                      ₹1000-5000
                    </label>
                  </div>
                </div>
                <div class="shop-sidebar-price-range">
                  <h4 class="sidebar-title">Sale Product</h4>
                  <div class="sidebar-price-range">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={showSaleOnly}
                        onChange={() => setShowSaleOnly(!showSaleOnly)}
                      />
                      On Sale
                    </label>
                  </div>
                </div>

                <div className='shop-sidebar-size'>
                  <h4 className="sidebar-title">Color</h4>
                  <ul className="color-list">
                    {(showAllColors ? colors : colors.slice(0, 4)).map((color, index) => (
                      <label key={color._id} className="checkbox-label">
                        <input
                          type="checkbox"
                          checked={selectedColor === color._id}
                          onChange={() => setSelectedColor(color._id)}
                        />
                        {color.colorName}
                      </label>
                    ))}
                    <div className="viewmore">
                      {colors.length > 4 && (
                        <a onClick={() => setShowAllColors(!showAllColors)}>
                          {showAllColors ? 'View Less' : 'View More+'}
                        </a>
                      )}
                    </div>
                  </ul>
                </div>
                <div class="shop-sidebar-size">
                  <h4 class="sidebar-title">Size</h4>
                  <div class="sidebar-size">
                    <ul class="size-list">
                      {sizes && sizes.length > 0 ? (
                        sizes.map((size) => (
                          <label key={size.id} className="checkbox-label">
                            <input
                              type="checkbox"
                              checked={selectedSize === size._id}
                              onChange={() => handleSizeChange(size._id)}
                            />
                            {size.sizeName}
                          </label>
                        ))
                      ) : (
                        <p>Loading...</p>
                      )}

                    </ul>
                  </div>
                </div>

              </div>
            </div>

            <div className={menuOpen ? "das-header mobile-nav-menu show" : "das-header mobile-nav-menu"} id="nav-menu">
              <div className="loog">

                <div className="mobile-menu">
                  <div className="newerrow">

                    <div className="sidbtn">

                    </div>
                    <div className="sid">
                      <button className={menuOpen ? "menu-toggler active" : "menu-toggler"} id='menu-close' type="button" onClick={closeMenu}>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                      </button>
                    </div>
                  </div>

                  <div class="shop-sidebar">

                    <div class="shopsidebarcategory">
                      <h4 class="sidebartitle">Filter</h4>
                      <div class="sidebar-category">
                        <ul class="category-list mb--0">
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              name="filter"
                              checked={selectedGender === '1'}
                              onChange={() => setSelectedGender('1')}
                            />
                            Men
                          </label>

                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              name="filter"
                              checked={selectedGender === '2'}
                              onChange={() => setSelectedGender('2')}
                            />
                            Women
                          </label>

                          <label className="checkbox-label">

                            <input type="checkbox" name="filter" />
                            Kid's
                          </label>
                        </ul>
                      </div>
                    </div>
                    <div class="shopsidebarsize">
                      <h4 class="sidebartitle">Category</h4>
                      <div class="sidebar-size">
                        <ul class="size-list">
                          {visibleCategories && visibleCategories.length > 0 ? (
                            visibleCategories.map((category) => (
                              <label key={category.id} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  // name="filter"
                                  checked={selectedCategory === category._id}
                                  onChange={() => setSelectedCategory(category._id)}
                                />
                                {category.categoryName}
                              </label>
                            ))
                          ) : (
                            <p>Loading...</p>
                          )}
                          <div className='viewmore'>
                            {Category.length > 3 && (
                              <a onClick={handleViewMoreCategoriesClick}>
                                {showAllCategories ? 'Show Less ' : 'View More+'}
                              </a>
                            )}
                          </div>
                        </ul>
                      </div>
                    </div>
                    <div class="shopsidebarbrand">
                      <h4 class="sidebartitle">Brand</h4>
                      <div class="sidebar-brand">
                        <ul class="brand-list mb--0">
                          {visibleBrands && visibleBrands.length > 0 ? (
                            visibleBrands.map((brand) => (
                              <label
                                key={brand.id}
                                className="checkbox-label"
                              >
                                <input
                                  type="checkbox"
                                  // name="filter"
                                  className={selectedBrand === brand._id}
                                  onChange={() => setSelectedBrand(brand._id)}
                                />

                                {brand.brandName}
                              </label>
                            ))
                          ) : (
                            <p>Loading...</p>
                          )}
                          <div className='viewmore'>
                            {brands.length > 4 && (
                              <a onClick={handleViewMoreClick}>
                                {showAllBrands ? 'Show Less' : 'View More+'}
                              </a>
                            )}
                            {/* ... Render other filters */}
                          </div>
                        </ul>
                      </div>
                    </div>

                    <div class="shopsidebarpricerange">
                      <h4 class="sidebartitle">Price Range</h4>
                      <div class="sidebar-price-range">
                        <label className="checkbox-label">

                          <input type="checkbox" name="filter" />
                          ₹500-1000
                        </label>
                        <label className="checkbox-label">

                          <input type="checkbox" name="filter" />
                          ₹1000-5000
                        </label>
                      </div>
                    </div>
                    <div class="shopsidebarpricerange">
                      <h4 class="sidebartitle">Sale Product</h4>
                      <div class="sidebar-price-range">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            checked={showSaleOnly}
                            onChange={() => setShowSaleOnly(!showSaleOnly)}
                          />
                          On Sale
                        </label>
                      </div>
                    </div>


                    <h4 className="sidebartitle">Color</h4>
                    <ul className="color-list">
                      {(showAllColors ? colors : colors.slice(0, 4)).map((color, index) => (
                        <label key={color._id} className="checkbox-label">
                          <input
                            type="checkbox"
                            checked={selectedColor === color._id}
                            onChange={() => setSelectedColor(color._id)}
                          />
                          {color.colorName}
                        </label>
                      ))}
                      <div className="viewmore">
                        {colors.length > 4 && (
                          <a onClick={() => setShowAllColors(!showAllColors)}>
                            {showAllColors ? 'View Less' : 'View More+'}
                          </a>
                        )}
                      </div>
                    </ul>

                    <div class="shopsidebarsize">
                      <h4 class="sidebartitle">Size</h4>
                      <div class="sidebar-size">
                        <ul class="size-list">
                          {sizes && sizes.length > 0 ? (
                            sizes.map((size) => (
                              <label key={size.id} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  checked={selectedSize === size._id}
                                  onChange={() => handleSizeChange(size._id)}
                                />
                                {size.sizeName}
                              </label>
                            ))
                          ) : (
                            <p>Loading...</p>
                          )}

                        </ul>
                      </div>
                    </div>
                    <div class="shopsidebarsize">

                    </div>


                  </div>

                </div>

              </div>



            </div>
          </div>
        </div>

      </section>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />
    </>
  );
};

export default ProductList;
