import React from 'react'
import './App.css';
import { BrowserRouter as Router, Routes, Route, useParams, unstable_HistoryRouter, Navigate, } from "react-router-dom";
import HomeLayout from './Layouts/HomeLayout';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Cart from './Pages/Cart/Cart';
import Checkout from './Pages/Checkout/Checkout';
import Login from './Pages/Login/Login';
import Profile from './Pages/Profile/Profile';
import SignUp from './Pages/SignUp/SignUp';
import ForgotPass from './Pages/ForgotPass/ForgotPass';
import Faqs from './Pages/Faqs/Faqs';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import ProductList from './Pages/ProductList/ProductList';
import WishList from './Pages/WishList/WishList';
import OrderDetails from './Pages/OrderDetails/OrderDetails';
import ContactUs from './Pages/ContactUs/ContactUs';
import TermsandCondition from './Pages/TermsandCondition/TermsandCondition';
import EditProfile from './Pages/EditProfile/EditProfile';
import ProfileLayout from './Layouts/ProfileLayout';
import ProductDetail from './Pages/ProductDetails/ProductDetail';
import Payment from './Pages/Payment/Payment';
import ForgotpassOtp from './Pages/ForgotpassOtp/ForgotpassOtp';
import ResetPass from './Pages/Resetpass/ResetPass';
import { toast, ToastContainer } from 'react-toastify';
import Order from './Order/Order';



function App() {
  return (
    <>
      <div>
        <Router>
          <Routes>
            <Route path='/' element={<HomeLayout Page={<Home />} />} />

            <Route path='/about' element={<HomeLayout Page={<About />} />} />
            <Route path='/cart' element={<HomeLayout Page={<Cart />} />} />
            <Route path='/payment' element={<HomeLayout Page={<Payment />} />} />
            <Route path='/wishlist' element={<HomeLayout Page={<WishList />} />} />
            <Route path='/checkout' element={<HomeLayout Page={<Checkout />} />} />
            <Route path='/login' element={<HomeLayout Page={<Login />} />} />
            <Route path='/signup' element={<HomeLayout Page={<SignUp />} />} />
            <Route path='/forgotpass' element={<HomeLayout Page={<ForgotPass />} />} />
            <Route path='/forgotpassotp' element={<HomeLayout Page={<ForgotpassOtp />} />} />
            <Route path='/resetpass' element={<HomeLayout Page={<ResetPass />} />} />
            <Route path='/faqs' element={<HomeLayout Page={<Faqs />} />} />
            <Route path='/privacypolicy' element={<HomeLayout Page={<PrivacyPolicy />} />} />

            <Route path='/productdetail/:id' element={<HomeLayout Page={<ProductDetail />} />} />
            <Route path='/productlist' element={<HomeLayout Page={<ProductList />} />} />
            <Route path='/contact' element={<HomeLayout Page={<ContactUs />} />} />
            <Route path='/termsandcondition' element={<HomeLayout Page={<TermsandCondition />} />} />
           
            <Route path='/Profile' element={<ProfileLayout Page={<Profile />} />} />
            <Route path='/orderdetails/:id' element={<ProfileLayout Page={<OrderDetails />} />} />
            <Route path='/editprofile' element={<ProfileLayout Page={<EditProfile />} />} />
            <Route path='/order' element={<ProfileLayout Page={<Order />} />} />
            
          </Routes>
          
          <ToastContainer
          position='bottom-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      
        </Router>


      </div>
    </>
  )
}

export default App