import React, { useState } from 'react';
import axios from 'axios';
import { Link, NavLink } from 'react-router-dom';

const ResetPass = () => {
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reenterPassword, setReenterPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      email: email,
      mobileNumber: mobileNumber,
      newPassword: newPassword,
    };

    try {
      const response = await axios.post('http://192.168.1.20:3000/api/user/otp/generateOtp', requestData);

      if (response.status === 200 && response.data.message === 'Password reset successful') {
        setSuccessMessage('otp verify');
      }
    } catch (error) {
      // Handle API request error
      setError('Error occurred. Please try again.');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (newPassword !== reenterPassword) {
      setPasswordsMatch(false);
      return;
    }

    // Perform password reset logic here
    try {
      // Your password reset API request
      // ...

      // Assuming successful reset
      setSuccessMessage('Password reset successfully');
    } catch (error) {
      setError('Error resetting password. Please try again.');
    }
  };

  return (
    <>
       <section className='regularbanner'>
        <div className="bannnercontent">
          <h2 className='bannertitle'>create password</h2>
          <NavLink> <a href="/" className='bannfirstlink'>Home </a><a href="/" className='bannlink'>/ create password</a></NavLink>
        </div>
      </section>
      <div className="forgot-password">
        <h2>Reset Password</h2>
        <form onSubmit={handleResetPassword}>
        <div className="borderlogin"></div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="reenterPassword">Re-enter Password</label>
            <input
              type="password"
              id="reenterPassword"
              name="reenterPassword"
              value={reenterPassword}
              onChange={(e) => setReenterPassword(e.target.value)}
              required
            />
          </div>
          {!passwordsMatch && <p className="error-message">Passwords do not match.</p>}
          <button type="submit" className='submitlogin'>
            Reset Password
          </button>
        </form>
      </div>
    </>
  );
};

export default ResetPass;
