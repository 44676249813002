import './Payment.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate,NavLink } from 'react-router-dom';
const PaymentComponent = () => {
   const [orderSubmitted, setOrderSubmitted] = useState(false);
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([
    
  ]);
  const token = localStorage.getItem('token') || sessionStorage.getItem('token'); // Retrieve the token from local storage
  
  const isLoggedIn = !!token; 
  const handleFinishOrder = async () => {
    try {
      const orderData = {
        user: {
          userId: '', // Replace with the actual user ID
          name: '',
          email: '',
          // Add other user-related information as needed
        },
        products: cartItems.map((item) => ({
          productId: item.productId,
          productName: item.productName,
          productPrice: item.productPrice,
          productQuantity: item.productQuantity,
          // Add other product-related information as needed
        })),
      };

      const token = localStorage.getItem('token') || sessionStorage.getItem('token'); // Retrieve the token from local storage
      
      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        // Make the API request with the token in the headers
        const response = await axios.post(
          'http://192.168.1.13:3000/api/order/user/addOrder',
          orderData,
          config
        );

        // Handle the response from the server

        if (response.status === 200) {
          // Order was successfully submitted
          setOrderSubmitted(true);

          // Navigate to the order details page
          navigate('/order', );
        } else {
          // Handle errors or display error messages
          console.error('Error submitting order:', response.data);
        }
      } else {
        console.error('User is not authenticated. Please log in to place an order.');
      }
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };

 
 
  return (

    <>
    <section className='regularbanner'>
    <div className="bannnercontent">
        <h2 className='bannertitle'>payment</h2>
         <NavLink> <a href="/" className='bannfirstlink'>Home </a><a href="" className='bannlink'>/ payment </a></NavLink>
      </div>
  </section>
    <div className="payment">
      <div className="payment-tabs">
        <a className="active" href="javascript:void(0);">
          Payment
        </a>
      </div>
      <div className="payment-customer">
        <h2>Ivan Yew</h2>
      </div>
      
      <div className="payment-input">
        <div className="form-group success">
          <label htmlFor="ccnum">Credit Card Number</label>
          <input
            type="text"
            id="ccnum"
            className="form-control is-valid"
            name="ccnum"
            
          />
        </div>
        <div className="form-group half pr-3">
          <label htmlFor="ccmonth">Expiry Date</label>
          <div className="row mx-0">
            <div className="col-6 pl-0 pr-2">
              <select id="ccmonth" className="form-control">
                {[...Array(12).keys()].map((i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6 pl-2 pr-0">
              <select id="ccyear" className="form-control">
                {[...Array(8).keys()].map((i) => (
                  <option key={i} value={2020 + i}>
                    {2020 + i}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="form-group half pl-3">
          <label htmlFor="cccode">CVV Code</label>
          <input type="text" id="cccode" className="form-control is-invalid" name="cccode" />
        </div>
        <div className="form-group">
          <label htmlFor="ccname">Name on Card</label>
          <input type="text" id="ccname" className="form-control" name="ccname" />
        </div>
      </div>
      <div className="payment-actions">
        <button onClick={handleFinishOrder}>Finish Order</button>
      </div>
      {orderSubmitted && <p>Order Submitted Successfully!</p>}
    </div>
    </>
    
  );
};

export default PaymentComponent;
