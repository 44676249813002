import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPass.css';
import { Link, Navigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';



const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [navigateToOtp, setNavigateToOtp] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const [mobileNumber, setMobileNumber] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reenterPassword, setReenterPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true); // Add a state for tracking password match
  const [error, setError] = useState(null); // Add a state for handling errors
  const [successMessage, setSuccessMessage] = useState('');




  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const requestData = {
      email: email,
    };
  
    try {
      const response = await axios.post('http://192.168.1.20:3000/api/user/otp/generateOtp', requestData);
  
      if (response) {
        // Set navigateToOtp to trigger the navigation
        setNavigateToOtp(true);
      }
    } catch (error) {
      // Handle API request error
    }
  };

  
  return (
    <>
    <section className='regularbanner'>
        <div className="bannnercontent">
          <h2 className='bannertitle'>Forgot Password</h2>
          <NavLink> <a href="/" className='bannfirstlink'>Home </a><a href="/" className='bannlink'>/ Forgot Password </a></NavLink>
        </div>
      </section>
    <div className="forgot-password">
      <h2>Forgot Password</h2>
      <div className="borderlogin"></div>

      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      
      <form onSubmit={handleSubmit}>

        <div className="form-group">
          <label htmlFor="email">Email
            <span class="required">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
       
        
        <button type="submit" className='submitlogin'  >Submit</button>
        {navigateToOtp && <Navigate to="/forgotpassotp" />}
        <p className="mb-0 mt-4 text-center">
        <Link to="/login" className="linkk">
          Back to Login
        </Link>
      </p>
      </form>
     
    </div>
    </>
  );
};

export default ForgotPassword;
