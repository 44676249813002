import axios from 'axios';
import React, { createContext, useState, useContext,useEffect } from 'react';
const CartContext = createContext();
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [count,setCount] =useState(1)
  const [countforbag,setcountforbag] =useState(1)
  const [wishlistData, setWishlistData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const addToCart = (product) => {
    setCart((prevCart) => [...prevCart, product]);
  };
  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((item) => item.productId !== productId));
  };
  const clearCart = () => {
    setCart([]);
  };
  const increaseCount = () => {
    setCount(count + 1);
};
  const increaseCountforbag = () => {
    setcountforbag(count + 1);
};
  const removeFromWishlist = async (productId) => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const response = await axios.delete(`https://dev.ahura.xyz:3002/api/wishlist/user/deleteProductFromWishlist/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        // Update the wishlist data in the context after successfully deleting the product
        setWishlistData((prevWishlist) => prevWishlist.filter((item) => item.productId !== productId));
        console.log('Product removed from wishlist successfully');
      } else {
        console.log('Unexpected response structure:', response.data);
        // Handle the error as needed
      }
    } catch (error) {
      console.error('Error removing the product from the wishlist:', error);
      if (error.response) {
        console.log('Server response data:', error.response.data);
        // Handle the error as needed
      }
    }
  };
  useEffect(() => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      axios
        .get('https://dev.ahura.xyz:3002/api/wishlist/user/getWishlist', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const updatedWishlistData = response.data.result.docs.map((item) => ({
            ...item,
            showDislikeButton: false,
          }));
          setWishlistData(updatedWishlistData);
        })
    }
  }, [count]);
  useEffect(() => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    axios
      .get('https://dev.ahura.xyz:3002/api/cart/user/getCart', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Set the cart items with the data retrieved from the API
        setCartItems(response.data.result.docs);
        // After setting cart items from the API response
        localStorage.setItem('cartItems', JSON.stringify(response.data.result.docs));
      })
      .catch((error) => {
        console.error('Error fetching cart:', error);
      });
  }, [countforbag]);
  
  return (
    <CartContext.Provider value={{ cart,removeFromWishlist, addToCart, removeFromCart, clearCart ,wishlistData,cartItems,increaseCount,increaseCountforbag}}>
      {children}
    </CartContext.Provider>
  );
};
export const useCart = () => {
  return useContext(CartContext);
};