import React, { useState } from 'react';
import axios from 'axios';
import './SignUp.css';
import { useNavigate } from "react-router-dom";
import { NavLink,} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Signup = () => {
  const navigate = useNavigate();
  const [formDatas, setFormDatas] = useState({
    username: '',
    userMobileNo: '',
    userGender: '',
    password: '',
    email: '',
  });

  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDatas({
      ...formDatas,
      [name]: value,
    });
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!formDatas.username || !formDatas.userMobileNo || !formDatas.userGender || !formDatas.password) {
      setError('Please fill in all the fields.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('userMobileNo', formDatas.userMobileNo);
      formData.append('password', formDatas.password);
      formData.append('userName', formDatas.username);
      formData.append('userGender', formDatas.userGender);
      formData.append('email', formDatas.email);

      const response = await axios.post('https://dev.ahura.xyz:3002/api/user/signup', formData);

      if (response.status === 201 && response.data.message === 'User has been added successfully') {
        // Login after successful signup
        toast.success('Registered successfully!');
       
        handleLoginAfterSignup();
      } else {
        setError('An error occurred while signing up. Please try again later.');
      }
    } catch (error) {
      console.error('Error signing up:', error);
      setError('An error occurred while signing up. Please try again later.');
    }
  };

  const handleLoginAfterSignup = () => {
    const loginData = {
      userMobileNo: formDatas.userMobileNo,
      password: formDatas.password,
      email: formDatas.email,
    };
    axios
      .post('https://dev.ahura.xyz:3002/api/user/userLogin', loginData)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userName', formDatas.username);
          localStorage.setItem('userMobileNo', formDatas.userMobileNo);
          localStorage.setItem('email', formDatas.email);
          navigate('/profile');
        } else {
          setError('Login after signup failed. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Login after signup failed:', error);
        setError('Login after signup failed. Please try again.');
      });
  };


  return (
    <>
    <section className='regularbanner'>
    <div className="bannnercontent">
        <h2 className='bannertitle'>REGISTER</h2>
         <NavLink> <a href="/" className='bannfirstlink'>Home </a><a href="" className='bannlink'>/ REGISTER </a></NavLink>
       
        
    </div>
  </section>
    <div className="signup">
       <h2>REGISTER</h2>
      <div className="borderlogin"></div>

      {error && <div className="error-message">{error}</div>}
      
      <form onSubmit={handleSignup}>
        <div className="form-group">
          <label htmlFor="username">Username
          <span class="required">*</span>
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={formDatas.username}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="useremail">Email
          <span class="required">*</span>
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formDatas.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="userMobileNo">Mobile Number
          <span class="required">*</span>
          </label>
          <input
            type="tel"
            id="userMobileNo"
            name="userMobileNo"
            value={formDatas.userMobileNo}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
       
          <div className='genderflex'>
          <label>Gender
          <span class="required">*</span>
             </label>
             <div className="femaleradiobtn">
             <label>
              <input
                type="radio"
                name="userGender"
                value="1"
                checked={formDatas.userGender === '1'}
                onChange={handleChange}
                required
              />
              Male 
            </label>
             </div>
             <div className="maleradiobtn">
             <label>
              <input
                type="radio"
                name="userGender"
                value="2"
                checked={formDatas.userGender === '2'}
                onChange={handleChange}
                required
              />
              Female
            </label>
            
             </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="password">Password
          <span class="required">*</span>
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formDatas.password}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className='submitlogin'>Sign Up</button>
        <div className="already-have-account">
       <a  className="linkk">Already have an account? </a> <a href="/login" className="linkk">Login</a>
       
      </div>
      </form>
      
    </div>
    <ToastContainer
        position='bottom-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Signup;
